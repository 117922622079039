import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4rem;
  width: 100%;
`;

interface ArrowIconProps {
  $disabled: boolean;
  $left?: boolean;
}

export const ArrowIcon = styled.div<ArrowIconProps>`
  fill: ${(props) =>
    props.$disabled ? props.theme.colors.gray : props.theme.colors.primary};
  margin: ${(props) => (props.$left ? '0 1rem 0 0' : '0 0 0 1rem')};
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    opacity: 0.8;
  }
`;
