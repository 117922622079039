import styled from 'styled-components';

export const PopupWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

export const DarkOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.primary};
  opacity: 0.7;
  cursor: pointer;
`;

interface StyledPopupProps {
  $small?: boolean;
}

export const StyledPopup = styled.div<StyledPopupProps>`
  background-color: ${(props) => props.theme.colors.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  width: ${(props) => (props.$small ? '35vw' : '50vw')};
  max-height: 75vh;
  overflow: scroll;

  @media (max-width: 900px) {
    width: 95%;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ExitButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  margin-left: 4rem;
  cursor: pointer;

  & svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;
