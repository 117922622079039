import { Ol, Ul } from 'components/Dashboard/RichTextEditor/styled';

interface Props {
  attributes: any;
  children: React.ReactNode;
  element: any;
}

export const Element = ({ attributes, children, element }: Props) => {
  switch (element.type) {
    case 'bulleted-list':
      return <Ul {...attributes}>{children}</Ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <Ol {...attributes}>{children}</Ol>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};
