import { DefaultTheme } from 'styled-components';
import { createTheme } from '@mui/material/styles';

const theme: DefaultTheme = {
  colors: {
    primary: '#1A1A1A',
    light: '#F4F4F4',
    white: '#FFFFFF',
    gray: {
      dark: '#757575',
      light: '#CCC',
      veryLight: '#eeeeee',
    },
    error: {
      main: '#d32f2f',
      background: 'rgb(253, 237, 237)',
      text: 'rgb(95, 33, 32)',
    },
    success: {
      background: '#e8f5e9',
      text: 'rgb(30, 70, 32)',
    },
    border: {
      gray: '#bdbdbd',
    },
  },
  breakpoints: {
    mobile: '900px',
  },
};

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#1A1A1A',
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: 'Space Grotesk, sans-serif',
  },
});

export default theme;
