import { useState } from 'react';
import { coaches } from './coaches';
import LogoImage from 'assets/images/logo.svg';
import { Title, Subtitle, Text, LoadMoreButton } from 'theme/GlobalStyles';
import {
  AboutUsContainer,
  Header,
  Description,
  Logo,
  StyledText,
  TextDivider,
  BoldText,
  ImageWrapper,
  DarkOverlay,
  ProfileImage,
  Cards,
  Card,
  CardTitle,
  CardSubtitle,
  BulletPoint,
  AdditionalNote,
} from './styled';
import Collapse from '@mui/material/Collapse';
import { useAppSelector } from 'redux/hooks';
import { selectMobile } from 'redux/App/slice';

export default function AboutUs() {
  const [textExpanded, setTextExpanded] = useState(false);

  const mobile = useAppSelector(selectMobile);

  return (
    <AboutUsContainer>
      <Header>
        {mobile && <Logo src={LogoImage} alt="Infamous logo" />}

        <Description>
          <Title>Meet our crew</Title>
          <StyledText>A-uuuu!</StyledText>
          <Text>
            <BoldText>Infamous Skydiving Team</BoldText> was founded in 2018 by{' '}
            <BoldText>Jakub “Kuba” Langowsky and Piotr “Novi” Noworol</BoldText>{' '}
            in Poland and was the first modern school of freeflying on the local
            market at that time.
          </Text>
          <TextDivider />
          <Text>
            We have started from organising events called Skill Camp on local
            dropzone - Sky Force. By the years school was growing rapidly,
            organising more events. In 2021 <BoldText>Jakub Organista</BoldText>{' '}
            joined the Kuba & Novi team and created so called Core of Coaches in
            the <BoldText>Infamous Skydiving Team</BoldText>.
          </Text>

          <Collapse in={textExpanded} unmountOnExit>
            <TextDivider />
            <Text>
              We organise around 20+ skydiving events a year. Our Core Coaches
              have been invited to many big events around Europe in various
              countries such as:{' '}
              <BoldText>
                Germany, Spain, Sweden, Estonia, UK, Belgium, Cyprus, Slovakia
              </BoldText>
              .
            </Text>

            <TextDivider />

            <Text>
              During those years we have created a{' '}
              <BoldText>recognised trademark</BoldText> and base of happy
              customers who come back frequently to our events.
            </Text>

            <TextDivider />

            <Text style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
              LOAD ORGANASING:
            </Text>

            <ul>
              <li style={{ listStyle: 'none' }}>
                <Text style={{ fontWeight: 'bold' }}>
                  - Xmass Boogie Algarve 2016,
                </Text>
              </li>
              <li style={{ listStyle: 'none' }}>
                <Text style={{ fontWeight: 'bold' }}>
                  - Flanders Boogie 2018,
                </Text>
              </li>
              <li style={{ listStyle: 'none' }}>
                <Text style={{ fontWeight: 'bold' }}>
                  - Xmass Boogie Skydive Spain 2021 and 2022, and many more
                  around Europe.
                </Text>
              </li>
            </ul>
          </Collapse>

          <LoadMoreButton onClick={() => setTextExpanded((value) => !value)}>
            {textExpanded ? 'Read less' : 'Read more'}
          </LoadMoreButton>
        </Description>

        {!mobile && <Logo src={LogoImage} alt="Infamous logo" />}
      </Header>

      <Cards>
        {coaches.map((coach) => (
          <Card key={coach.id}>
            <ImageWrapper>
              <ProfileImage src={coach.image} alt={coach.name} />
              <DarkOverlay />
            </ImageWrapper>

            <CardTitle>
              <Subtitle>{coach.name}</Subtitle>
            </CardTitle>

            <CardSubtitle>{coach.subtitle}</CardSubtitle>

            <Text>{coach.description.title}</Text>
            <ul>
              {coach.description.bulletPoints.map((point) => (
                <BulletPoint key={point.id}>{point.name}</BulletPoint>
              ))}
            </ul>

            <AdditionalNote>{coach.additionalNote}</AdditionalNote>
          </Card>
        ))}
      </Cards>
    </AboutUsContainer>
  );
}
