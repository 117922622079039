import styled from 'styled-components';
import { Container } from 'theme/GlobalStyles';

export const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;

export const Content = styled(Container)`
  margin-top: 8rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Header = styled.header`
  margin-top: 4rem;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export const FootageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10rem;
  margin-top: 4rem;
  width: 100%;

  @media (max-width: 900px) {
    display: block;
  }
`;

export const Footage = styled.article`
  max-width: 48rem;
  height: 100%;
  position: relative;

  @media (max-width: 900px) {
    &:not(:first-of-type) {
      margin-top: 4rem;
    }
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 25rem;
  position: relative;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 900px) {
    height: 20rem;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1a1a;
  opacity: 0.4;
`;

export const PlayButton = styled.svg`
  stroke: ${(props) => props.theme.colors.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Details = styled.div`
  margin-top: 2.5rem;

  @media (max-width: 900px) {
    margin-top: 1.5rem;
  }
`;

export const FootageTitle = styled.div`
  margin-top: 1rem;
  border-top: 0.2rem solid ${(props) => props.theme.colors.primary};
  padding-top: 1rem;
`;

export const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
  margin-top: 2rem;
  width: 100%;
`;

export const StyledIframe = styled.iframe`
  outline: none;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;
