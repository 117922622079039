import styled, { keyframes } from 'styled-components';
import { Container, Title } from 'theme/GlobalStyles';

export const HeroContainer = styled.section`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
`;

export const DarkOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: linear-gradient(#6c6c6c, #000000);
  opacity: 0.57;
`;

export const HeroTitleContainer = styled(Container)`
  z-index: 100;
`;

export const TitleWrapper = styled.div`
  display: block;
  max-height: 15.5rem;

  @media (max-width: 900px) {
    max-height: 10rem;
  }
`;

const titleAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

interface AnimatedTitleWrapperProps {
  $num: number;
}

export const AnimatedTitleWrapper = styled(
  TitleWrapper,
)<AnimatedTitleWrapperProps>`
  top: calc(${(props) => props.$num} * 12rem);
  opacity: 0;
  animation: ${titleAnimation} 0.7s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: ${(props) => props.$num / 3}s;

  @media (max-width: 1500px) {
    top: calc(${(props) => props.$num} * 10rem);
  }

  @media (max-width: 1200px) {
    top: calc(${(props) => props.$num} * 8rem);
  }
`;

interface HeroTitleProps {
  $hidden?: boolean;
}

export const HeroTitle = styled.h1<HeroTitleProps>`
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
  display: block;
  text-align: center;
  line-height: 1.1;

  opacity: ${(props) => (props.$hidden ? 0 : 1)};

  @media (max-width: 1140px) {
    line-height: 1.3;
  }
`;

export const HeroOutlinedTitle = styled(HeroTitle)`
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${(props) => props.theme.colors.white};
`;

// MOBILE
export const MobileHeroTitle = styled(Title)`
  font-size: 6.4rem;
  color: ${(props) => props.theme.colors.white};
  margin-top: -15rem;

  @media (max-width: 350px) {
    font-size: 4.8rem;
  }

  @media (max-height: 700px) {
    margin-top: 0;
  }
`;
