import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface EnhancedTableToolbarProps {
  numSelected: number;
  handleAddNewEventClick: () => void;
  handleEditEventClick: () => void;
  handleChangeSignupStatusClick: (status: boolean) => void;
  handleDeleteEventsClick: () => void;
}

export default function TableToolbar(props: EnhancedTableToolbarProps) {
  const {
    numSelected,
    handleAddNewEventClick,
    handleEditEventClick,
    handleChangeSignupStatusClick,
    handleDeleteEventsClick,
  } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} zaznaczonych
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Eventy
        </Typography>
      )}
      {numSelected > 0 ? (
        <>
          <Button
            variant="text"
            onClick={() => handleChangeSignupStatusClick(false)}
            sx={{ minWidth: '15rem' }}
          >
            Otwórz zapisy
          </Button>

          <Button
            variant="text"
            onClick={() => handleChangeSignupStatusClick(true)}
            sx={{ minWidth: '15rem' }}
          >
            Zamknij zapisy
          </Button>

          {numSelected === 1 && (
            <Tooltip title="Edytuj">
              <IconButton onClick={handleEditEventClick}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Usuń">
            <IconButton onClick={handleDeleteEventsClick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Button
          variant="contained"
          size="small"
          sx={{ flexShrink: 0 }}
          onClick={handleAddNewEventClick}
        >
          Dodaj event
        </Button>
      )}
    </Toolbar>
  );
}
