import styled from 'styled-components';

export const AlertIcon = styled.div`
  width: 2.4rem;
  height: 2.4rem;
`;

interface AlertProps {
  type: 'error' | 'success';
}

export const AlertWrapper = styled.div<AlertProps>`
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.type === 'error'
      ? props.theme.colors.error.background
      : props.theme.colors.success.background};
  color: ${(props) =>
    props.type === 'error'
      ? props.theme.colors.error.text
      : props.theme.colors.success.text};
  margin-top: 2rem;

  ${AlertIcon} {
    fill: ${(props) =>
      props.type === 'error'
        ? props.theme.colors.error.text
        : props.theme.colors.success.text};
  }
`;

export const AlertText = styled.p`
  margin-left: 2rem;
`;
