import { Button as StyledButton } from 'theme/GlobalStyles';

import { Content } from './styled';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  dark?: boolean;
  long?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit';
}

function Button({
  onClick,
  children,
  dark,
  long,
  disabled,
  type,
}: ButtonProps) {
  return (
    <StyledButton
      $dark={dark}
      $long={long}
      $disabled={disabled}
      onClick={onClick}
      type={type || 'button'}
    >
      <Content>
        {children}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21.595"
          height="21.595"
          viewBox="0 0 21.595 21.595"
        >
          <path
            d="M170.915,346.092l-1.17-1.143,4.573-4.573H160v-1.633h14.317l-4.6-4.6,1.17-1.143,6.56,6.56Z"
            transform="translate(-348.604 -109.992) rotate(-45)"
            fill="#fff"
            className="buttonArrow"
          />
        </svg>
      </Content>
    </StyledButton>
  );
}

export default Button;
