import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Logo = styled.img`
  margin-bottom: 4rem;
  width: 20%;

  @media (max-width: 900px) {
    width: 30%;
  }
`;
