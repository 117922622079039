import { RootState } from 'redux/store';

export const selectIsLoading = (state: RootState) =>
  state.participants.isLoading;
export const selectError = (state: RootState) => state.participants.error;
export const selectParticipants = (state: RootState) =>
  state.participants.participants;
export const selectTotalDocuments = (state: RootState) =>
  state.participants.totalDocuments;
export const selectSortBy = (state: RootState) => state.participants.sortBy;
export const selectSortByOrder = (state: RootState) =>
  state.participants.sortByOrder;
export const selectEventParticipants = (state: RootState) =>
  state.participants.eventParticipants;
export const selectNextQuery = (state: RootState) =>
  state.participants.nextQuery;
export const selectPrevQuery = (state: RootState) =>
  state.participants.prevQuery;
