import { createAsyncThunk } from '@reduxjs/toolkit';
import { Event } from 'api/models/Event';
import {
  fetchEvents,
  addEvent as dbAddEvent,
  updateEvent as dbUpdateEvent,
  deleteEvents as dbDeleteEvent,
  signupToEvent as dbSignupToEvent,
  signOffEvent as dbSignOffEvent,
  changeEventSignupsStatus as dbChangeEventsSignupsStatus,
  fetchNextPageEvents as dbFetchNextPageEvents,
  fetchPrevPageEvents as dbFetchPrevPageEvents,
  fetchEventById as dbFetchEventById,
} from 'api/controllers/event';
import { DocumentData, Query } from 'firebase/firestore';
import { Participant } from 'api/models/Participant';

export const getEvents = createAsyncThunk<
  | {
      events: Event[];
      totalDocuments: number;
      nextQuery: Query<DocumentData> | undefined;
    }
  | undefined,
  { pageSize: number; selectedMonth?: string; selectedYear?: string },
  { rejectValue: string }
>(
  'events/getEvents',
  async ({ pageSize, selectedMonth, selectedYear }, { rejectWithValue }) => {
    try {
      const results = await fetchEvents(pageSize, selectedMonth, selectedYear);
      return results;
    } catch (error: any) {
      if (!error.response.message) throw error;

      rejectWithValue(error.response.message);
    }
  },
);

export const getNextPageEvents = createAsyncThunk<
  | {
      events: Event[];
      nextQuery: Query<DocumentData> | undefined;
      prevQuery: Query<DocumentData>;
    }
  | undefined,
  {
    nextQuery: Query<DocumentData>;
    page: number;
    pageSize: number;
    totalDocuments: number;
    selectedMonth?: string;
    selectedYear?: string;
  },
  { rejectValue: string }
>(
  'events/getNextPageEvents',
  async (
    { nextQuery, page, pageSize, totalDocuments, selectedMonth, selectedYear },
    { rejectWithValue },
  ) => {
    try {
      const results = await dbFetchNextPageEvents(
        nextQuery,
        page,
        pageSize,
        totalDocuments,
        selectedMonth,
        selectedYear,
      );

      return results;
    } catch (error: any) {
      if (!error.response.message) throw error;

      rejectWithValue(error.response.message);
    }
  },
);

export const getPrevPageEvents = createAsyncThunk<
  | {
      events: Event[] | undefined;
      nextQuery: Query<DocumentData>;
      prevQuery: Query<DocumentData> | undefined;
    }
  | undefined,
  {
    prevQuery: Query<DocumentData>;
    page: number;
    pageSize: number;
    selectedMonth?: string;
    selectedYear?: string;
  },
  { rejectValue: string }
>(
  'events/getPrevPageEvents',
  async (
    { prevQuery, page, pageSize, selectedMonth, selectedYear },
    { rejectWithValue },
  ) => {
    try {
      const results = await dbFetchPrevPageEvents(
        prevQuery,
        page,
        pageSize,
        selectedMonth,
        selectedYear,
      );
      return results;
    } catch (error: any) {
      if (!error.response.message) throw error;

      rejectWithValue(error.response.message);
    }
  },
);

export const fetchEventById = createAsyncThunk<
  Event | undefined,
  string,
  { rejectValue: string }
>('events/fetchEventById', async (eventId, { rejectWithValue }) => {
  try {
    const event = await dbFetchEventById(eventId);
    return event;
  } catch (error: any) {
    if (!error.response.message) throw error;

    rejectWithValue(error.response.message);
  }
});

export const addEvent = createAsyncThunk<
  Event | undefined,
  { event: Event; eventImage: File },
  { rejectValue: string }
>('events/addEvent', async ({ event, eventImage }, { rejectWithValue }) => {
  try {
    const addedEvent = await dbAddEvent(event, eventImage);

    if (!addedEvent) throw new Error('Unexpected error');

    return addedEvent;
  } catch (error: any) {
    if (!error.response.message) throw error;

    rejectWithValue(error.response.message);
  }
});

export const updateEvent = createAsyncThunk<
  Event | undefined,
  { eventId: string; newEvent: Event; eventImage?: File | null },
  { rejectValue: string }
>('events/updateEvent', async (params, { rejectWithValue }) => {
  try {
    const updatedEvent = await dbUpdateEvent(params);
    return updatedEvent;
  } catch (error: any) {
    if (!error.response.message) throw error;

    rejectWithValue(error.response.message);
  }
});

export const changeEventSignupsStatus = createAsyncThunk<
  { eventIds: string[]; signupsBlocked: boolean } | undefined,
  { eventIds: string[]; signupsBlocked: boolean },
  { rejectValue: string }
>('events/changeEventSignupsStatus', async (params, { rejectWithValue }) => {
  try {
    const response = await dbChangeEventsSignupsStatus(params);
    return response;
  } catch (error: any) {
    if (!error.response.message) throw error;

    rejectWithValue(error.response.message);
  }
});

export const deleteEvents = createAsyncThunk<
  string[] | undefined,
  string[],
  { rejectValue: string }
>('events/deleteEvent', async (eventIds, { rejectWithValue }) => {
  try {
    const deletedIds = await dbDeleteEvent(eventIds);

    if (!deletedIds) throw new Error('Unexpected error');

    return deletedIds;
  } catch (error: any) {
    if (!error.response.message) throw error;

    rejectWithValue(error.response.message);
  }
});

export const signupToEvent = createAsyncThunk<
  Event | undefined,
  { eventId: string; participant: Participant },
  { rejectValue: string }
>('events/signupToEvent', async (params, { rejectWithValue }) => {
  try {
    const results = await dbSignupToEvent(params);

    if (results.status === 409) {
      throw new Error('Participant already signed up');
    } else if (results.status !== 200) {
      throw new Error('Unexpected error');
    }

    return results.event;
  } catch (error: any) {
    if (!error.response?.message) throw error;

    rejectWithValue(error.response.message);
  }
});

export const signOffEvent = createAsyncThunk<
  Event | undefined,
  { eventId: string; participantId: string },
  { rejectValue: string }
>('events/signOffEvent', async (params, { rejectWithValue }) => {
  try {
    const updatedEvent = await dbSignOffEvent(params);
    return updatedEvent;
  } catch (error: any) {
    if (!error.response.message) throw error;

    rejectWithValue(error.response.message);
  }
});
