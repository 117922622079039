import Coach1Image from 'assets/images/coaches/jakub-organista.png';
import Coach2Image from 'assets/images/coaches/piotr-noworol.jpg';
import Coach3Image from 'assets/images/coaches/jakub-langowski.jpg';

export const coaches = [
  {
    id: 1,
    name: 'Jakub Organista',
    image: Coach1Image,
    subtitle: '9 years in the sport, 3500+ jumps',
    description: {
      title: 'In VFS (Indoor Skydiving) with Ex-Presidents:',
      bulletPoints: [
        { id: 1, name: '- 4th place in the 4th FAI World Cup 2022' },
        { id: 2, name: '- 1st place in Czech Open 2022' },
        { id: 3, name: '- 2nd place in Flyspot Polish Open 2022/2021' },
        {
          id: 4,
          name: '- Polish Record holder in Head up and Head Down formation',
        },
      ],
    },
    additionalNote: undefined,
  },
  {
    id: 2,
    name: 'Piotr Noworol',
    image: Coach2Image,
    subtitle: '9 years in the sport, 2800+ jumps',
    description: {
      title: 'In VFS (Indoor Skydiving) with Flyspot Synapses Team:',
      bulletPoints: [
        { id: 1, name: '- 1st place in the World Cup 2024r' },
        { id: 2, name: '- 1st place in the European Championship 2024r' },
        { id: 3, name: '- 3rd place in the World championships 2023' },
        { id: 4, name: '- 2nd place in the European Championships 2022' },
        { id: 5, name: '- 3rd place in the World Cup 2022' },
        {
          id: 4,
          name: '- 1st place in Polish, Austrian, Slovakian Championships',
        },
      ],
    },
    additionalNote:
      'Organizer and Polish record holder in the first Head up and Head down formation.',
  },
  {
    id: 3,
    name: 'Jakub Langowski',
    image: Coach3Image,
    subtitle: '17 years in the sport, 6500+ jumps',
    description: {
      title: 'VFS Mulitmedalist and Multichampion of:',
      bulletPoints: [
        { id: 1, name: '- Poland 2019, 2020, 2021,' },
        { id: 2, name: '- Austria 2019, 2020' },
        {
          id: 3,
          name: '- Czech Republic 2022',
        },
        { id: 4, name: '- as Team Poland: World Cup Belgium - 4th' },
      ],
    },
    additionalNote:
      'Tunnel Coach and Instructor (Flyspot) with over 2500 hours of coaching and happy students. VFS Coach.',
  },
];
