import {
  SocialMediaWrapper,
  Content,
  SocialMediaTitle,
  Posts,
  Post,
} from './styled';

import SocialImage1 from 'assets/images/social/1.jpg';
import SocialImage2 from 'assets/images/social/2.jpg';
import SocialImage3 from 'assets/images/social/3.jpg';

export default function SocialMedia() {
  return (
    <SocialMediaWrapper>
      <Content>
        <SocialMediaTitle>Follow us on social media</SocialMediaTitle>

        <Posts>
          <a
            href="https://www.instagram.com/infamous_skydiving_team/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Post src={SocialImage1} alt="Social media" />
          </a>

          <a
            href="https://www.instagram.com/infamous_skydiving_team/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Post src={SocialImage2} alt="Social media" />
          </a>

          <a
            href="https://www.instagram.com/infamous_skydiving_team/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Post src={SocialImage3} alt="Social media" />
          </a>
        </Posts>
      </Content>
    </SocialMediaWrapper>
  );
}
