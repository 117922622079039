import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Participant } from 'api/models/Participant';
import { DocumentData, Query } from 'firebase/firestore';
import {
  fetchParticipants,
  fetchNextPageParticipants,
  fetchPrevPageParticipants,
  fetchEventParticipants,
} from './thunk';

export enum SortByOrder {
  Asc = 'asc',
  Desc = 'desc',
}

interface ParticipantsState {
  isLoading: boolean;
  error: string | undefined;
  participants: Participant[];
  eventParticipants: Participant[];
  totalDocuments: number;
  sortBy: keyof Participant;
  sortByOrder: SortByOrder;
  nextQuery: Query<DocumentData> | undefined;
  prevQuery: Query<DocumentData> | undefined;
}

const initialState: ParticipantsState = {
  isLoading: false,
  error: undefined,
  participants: [],
  eventParticipants: [],
  totalDocuments: 0,
  sortBy: 'surname',
  sortByOrder: SortByOrder.Asc,
  nextQuery: undefined,
  prevQuery: undefined,
};

const participantsSlice = createSlice({
  name: 'participants',
  initialState,
  reducers: {
    setSortBy: (state, { payload }: PayloadAction<keyof Participant>) => {
      state.sortBy = payload;
    },
    setSortByOrder: (state, { payload }: PayloadAction<SortByOrder>) => {
      state.sortByOrder = payload;
    },
  },
  extraReducers: (builder) => {
    // FETCH PARTICIPANTS
    builder.addCase(fetchParticipants.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchParticipants.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<
          | {
              participants: Participant[];
              totalDocuments: number;
              nextQuery: Query<DocumentData> | undefined;
            }
          | undefined
        >,
      ) => {
        state.isLoading = false;
        state.participants = payload ? payload.participants : [];
        state.totalDocuments = payload ? payload?.totalDocuments : 0;
        state.nextQuery = payload?.nextQuery;
      },
    );
    builder.addCase(fetchParticipants.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // FETCH NEXT PAGE PARTICIPANTS
    builder.addCase(fetchNextPageParticipants.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchNextPageParticipants.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<
          | {
              participants: Participant[] | undefined;
              nextQuery: Query<DocumentData> | undefined;
              prevQuery: Query<DocumentData>;
            }
          | undefined
        >,
      ) => {
        state.isLoading = false;
        state.participants = payload?.participants || [];
        state.nextQuery = payload?.nextQuery;
        state.prevQuery = payload?.prevQuery;
      },
    );
    builder.addCase(fetchNextPageParticipants.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // FETCH PREV PAGE PARTICIPANTS
    builder.addCase(fetchPrevPageParticipants.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchPrevPageParticipants.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<
          | {
              participants: Participant[] | undefined;
              nextQuery: Query<DocumentData>;
              prevQuery: Query<DocumentData> | undefined;
            }
          | undefined
        >,
      ) => {
        state.isLoading = false;
        state.participants = payload?.participants || [];
        state.nextQuery = payload?.nextQuery;
        state.prevQuery = payload?.prevQuery;
      },
    );
    builder.addCase(fetchPrevPageParticipants.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // FETCH PARTICIPANTS BY IDS
    builder.addCase(fetchEventParticipants.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchEventParticipants.fulfilled,
      (state, { payload }: PayloadAction<Participant[] | undefined>) => {
        state.isLoading = false;
        state.eventParticipants = payload || [];
      },
    );
    builder.addCase(fetchEventParticipants.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });
  },
});

export const { setSortBy, setSortByOrder } = participantsSlice.actions;

export default participantsSlice.reducer;
