import { createAsyncThunk } from '@reduxjs/toolkit';
import { Participant } from 'api/models/Participant';
import {
  fetchParticipants as dbFetchParticipants,
  fetchNextPageParticipants as dbFetchNextPageParticipants,
  fetchPrevPageParticipants as dbFetchPrevPageParticipants,
} from 'api/controllers/participant';
import { fetchEventParticipants as dbFetchEventParticipants } from 'api/controllers/event';
import { SortByOrder } from 'redux/participants/slice';
import { DocumentData, Query } from 'firebase/firestore';

export const fetchParticipants = createAsyncThunk<
  | {
      participants: Participant[];
      totalDocuments: number;
      nextQuery: Query<DocumentData> | undefined;
    }
  | undefined,
  { pageSize: number; sortBy: string; sortByOrder: SortByOrder },
  { rejectValue: string }
>(
  'participants/fetchParticipants',
  async ({ pageSize, sortBy, sortByOrder }, { rejectWithValue }) => {
    try {
      const results = await dbFetchParticipants(pageSize, sortBy, sortByOrder);
      return results;
    } catch (error: any) {
      if (!error.response.message) throw error;

      rejectWithValue(error.response.message);
    }
  },
);

export const fetchNextPageParticipants = createAsyncThunk<
  | {
      participants: Participant[] | undefined;
      nextQuery: Query<DocumentData> | undefined;
      prevQuery: Query<DocumentData>;
    }
  | undefined,
  {
    nextQuery: Query<DocumentData>;
    page: number;
    pageSize: number;
    sortBy: string;
    sortByOrder: SortByOrder;
    totalDocuments: number;
  },
  { rejectValue: string }
>(
  'participants/fetchNextPageParticipants',
  async (
    { nextQuery, page, pageSize, sortBy, sortByOrder, totalDocuments },
    { rejectWithValue },
  ) => {
    try {
      const results = await dbFetchNextPageParticipants(
        nextQuery,
        page,
        pageSize,
        sortBy,
        sortByOrder,
        totalDocuments,
      );

      return results;
    } catch (error: any) {
      if (!error.response.message) throw error;

      rejectWithValue(error.response.message);
    }
  },
);

export const fetchPrevPageParticipants = createAsyncThunk<
  | {
      participants: Participant[] | undefined;
      nextQuery: Query<DocumentData>;
      prevQuery: Query<DocumentData> | undefined;
    }
  | undefined,
  {
    prevQuery: Query<DocumentData>;
    page: number;
    pageSize: number;
    sortBy: string;
    sortByOrder: SortByOrder;
  },
  { rejectValue: string }
>(
  'participants/fetchPrevPageParticipants',
  async (
    { prevQuery, page, pageSize, sortBy, sortByOrder },
    { rejectWithValue },
  ) => {
    try {
      const results = await dbFetchPrevPageParticipants(
        prevQuery,
        page,
        pageSize,
        sortBy,
        sortByOrder,
      );

      return results;
    } catch (error: any) {
      if (!error.response.message) throw error;

      rejectWithValue(error.response.message);
    }
  },
);

export const fetchEventParticipants = createAsyncThunk<
  Participant[] | undefined,
  string,
  { rejectValue: string }
>(
  'participants/fetchEventParticipants',
  async (eventId, { rejectWithValue }) => {
    try {
      const participants = await dbFetchEventParticipants(eventId);
      return participants;
    } catch (error: any) {
      if (!error.response.message) throw error;

      rejectWithValue(error.response.message);
    }
  },
);
