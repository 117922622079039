import { format } from 'date-fns';
import pl from 'date-fns/locale/pl';

// Function for resizing hero text to the parent's width
const isOverflown = ({
  parent: { clientHeight, scrollHeight },
  noOffset,
}: {
  parent: {
    clientHeight: number;
    scrollHeight: number;
  };
  noOffset: boolean;
}) => {
  return noOffset
    ? scrollHeight > clientHeight
    : scrollHeight - 10 > clientHeight;
};

interface ResizeTextProps {
  elements: any;
  element?: any;
  minSize?: number;
  maxSize?: number;
  step?: number;
  noOffset?: boolean;
}

export const resizeText = ({
  element,
  elements,
  minSize = 4,
  maxSize = 15,
  step = 0.1,
  noOffset = false,
}: ResizeTextProps) => {
  (elements || [element]).forEach((el: any) => {
    let i = minSize;
    let overflow = false;

    const parent = el.parentNode;

    while (!overflow && i < maxSize) {
      el.style.fontSize = `${i}rem`;
      overflow = isOverflown({ parent, noOffset });

      if (!overflow) i += step;
    }

    // revert to last state where no overflow happened
    el.style.fontSize = `${i - step}rem`;
  });
};

export const renderDateRange = (dateRange: { start: Date; end: Date }) => {
  const startDate = format(dateRange.start, 'dd/MM/yyyy', {
    locale: pl,
  });

  const endDate = format(dateRange.end, 'dd/MM/yyyy', {
    locale: pl,
  });

  if (startDate === endDate) return startDate;

  return `${startDate} - ${endDate}`;
};

export const calculateDateQuery = (
  selectedMonth?: string,
  selectedYear?: string,
) => {
  let startDate: Date;
  let endDate: Date;

  if (!selectedMonth || !selectedYear)
    return { startDate: undefined, endDate: undefined };

  if (selectedMonth === 'all') {
    const futureYear = parseInt(selectedYear, 10) + 1;

    startDate = new Date(`${selectedYear}-01-01`);
    endDate = new Date(`${futureYear}-01-01`);

    return { startDate, endDate };
  }

  let futureMonth = `${parseInt(selectedMonth, 10) + 1}`;

  if (selectedMonth.length === 1) selectedMonth = `0${selectedMonth}`;
  if (futureMonth.length === 1) futureMonth = `0${futureMonth}`;

  startDate = new Date(`${selectedYear}-${selectedMonth}-01`);

  if (futureMonth === '13') {
    endDate = new Date(`${parseInt(selectedYear, 10) + 1}-01-01`);
  } else {
    endDate = new Date(`${selectedYear}-${futureMonth}-01`);
  }

  return { startDate, endDate };
};
