import { User } from 'api/models/User';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  user: User | undefined;
  children: JSX.Element;
}

export default function ProtectedRoute({
  user,
  children,
}: ProtectedRouteProps): JSX.Element {
  if (!user) {
    return <Navigate to="/admin-login" replace />;
  }

  return children;
}
