import styled from 'styled-components';
import { Container, Text } from 'theme/GlobalStyles';

export const StyledFooter = styled.footer`
  margin-top: 10rem;
  background-color: ${(props) => props.theme.colors.white};
  height: 13.5rem;

  @media (max-width: 900px) {
    height: 100%;
    padding: 4rem 0;
  }
`;

export const Content = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media (max-width: 900px) {
    display: block;
  }
`;

export const ContactInfo = styled.address`
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Logo = styled.img`
  width: 6rem;
  height: auto;
  margin-right: 2rem;

  @media (max-width: 900px) {
    margin-right: 0;
    margin-bottom: 4rem;
  }
`;

export const SocialMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  flex-direction: row;

  @media (max-width: 900px) {
    margin: 4rem 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const FooterText = styled(Text)`
  text-transform: uppercase;

  @media (max-width: 900px) {
    font-size: 1.4rem;
    text-align: center;
  }
`;

export const AddressElement = styled.a`
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.6rem;
  text-transform: uppercase;
  font-style: normal;

  @media (max-width: 900px) {
    margin: 0.5rem 0;
  }
`;
