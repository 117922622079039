import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Footage } from 'api/models/Footage';
import {
  fetchFootage,
  addFootage,
  updateFootage,
  deleteFootage,
  fetchNextPageFootage,
  fetchPrevPageFootage,
} from './thunk';
import { DocumentData, Query } from 'firebase/firestore';

interface FootageState {
  isLoading: boolean;
  error: string | undefined;
  footage: Footage[];
  totalDocuments: number;
  nextQuery: Query<DocumentData> | undefined;
  prevQuery: Query<DocumentData> | undefined;
}

const initialState: FootageState = {
  isLoading: false,
  error: undefined,
  footage: [],
  totalDocuments: 0,
  nextQuery: undefined,
  prevQuery: undefined,
};

const footageSlice = createSlice({
  name: 'footage',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    // FETCH FOOTAGE
    builder.addCase(fetchFootage.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchFootage.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<
          | {
              footage: Footage[];
              totalDocuments: number;
              nextQuery: Query<DocumentData> | undefined;
            }
          | undefined
        >,
      ) => {
        state.isLoading = false;
        state.footage = payload ? payload.footage : [];
        state.totalDocuments = payload ? payload.totalDocuments : 0;
        state.nextQuery = payload?.nextQuery;
      },
    );
    builder.addCase(fetchFootage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // FETCH NEXT PAGE FOOTAGE
    builder.addCase(fetchNextPageFootage.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchNextPageFootage.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<
          | {
              footage: Footage[];
              nextQuery: Query<DocumentData> | undefined;
              prevQuery: Query<DocumentData>;
            }
          | undefined
        >,
      ) => {
        state.isLoading = false;
        state.footage = payload?.footage || [];
        state.nextQuery = payload?.nextQuery;
        state.prevQuery = payload?.prevQuery;
      },
    );
    builder.addCase(fetchNextPageFootage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // FETCH PREV PAGE FOOTAGE
    builder.addCase(fetchPrevPageFootage.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchPrevPageFootage.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<
          | {
              footage: Footage[] | undefined;
              nextQuery: Query<DocumentData>;
              prevQuery: Query<DocumentData> | undefined;
            }
          | undefined
        >,
      ) => {
        state.isLoading = false;
        state.footage = payload?.footage || [];
        state.nextQuery = payload?.nextQuery;
        state.prevQuery = payload?.prevQuery;
      },
    );
    builder.addCase(fetchPrevPageFootage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // ADD FOOTAGE
    builder.addCase(addFootage.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      addFootage.fulfilled,
      (state, { payload }: PayloadAction<Footage | undefined>) => {
        state.isLoading = false;
        if (payload) state.footage.push(payload);
      },
    );
    builder.addCase(addFootage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // UPDATE FOOTAGE
    builder.addCase(updateFootage.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      updateFootage.fulfilled,
      (state, { payload }: PayloadAction<Footage | undefined>) => {
        state.isLoading = false;
        if (payload)
          state.footage = state.footage.map((f) =>
            f.id === payload.id ? payload : f,
          );
      },
    );
    builder.addCase(updateFootage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // DELETE FOOTAGE
    builder.addCase(deleteFootage.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      deleteFootage.fulfilled,
      (state, { payload }: PayloadAction<string[] | undefined>) => {
        state.isLoading = false;
        if (payload)
          state.footage = state.footage.filter((f) => !payload.includes(f.id!));
      },
    );
    builder.addCase(deleteFootage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });
  },
});

export default footageSlice.reducer;
