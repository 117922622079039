import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from 'api/models/User';
import { login } from './thunk';

interface AuthState {
  isLoading: boolean;
  user: User | undefined;
  error: string | undefined;
}

const initialState: AuthState = {
  isLoading: false,
  user: undefined,
  error: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User | undefined>) => {
      state.user = payload;
    },
  },
  extraReducers: (builder) => {
    // LOGIN
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      login.fulfilled,
      (state, { payload }: PayloadAction<User | undefined>) => {
        state.isLoading = false;
        state.user = payload;
      },
    );
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });
  },
});

export const { setUser } = authSlice.actions;

export default authSlice.reducer;
