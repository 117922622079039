import { Wrapper, Content, Header, Text } from './styled';

export default function ErrorPage() {
  return (
    <Wrapper>
      <Content>
        <Header>Oops!</Header>
        <Text>Sorry, this page doesn&apos;t exist</Text>
      </Content>
    </Wrapper>
  );
}
