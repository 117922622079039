import styled, { css } from 'styled-components';
import { Container, Card, Text } from 'theme/GlobalStyles';
import InputMask from 'react-input-mask';

export const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;

export const Content = styled(Container)`
  margin-top: 8rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Header = styled.header`
  margin-top: 4rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    margin-top: 4rem;
  }
`;

export const Events = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10rem;
  margin-top: 4rem;
  width: 100%;

  @media (max-width: 1400px) {
    gap: 5rem;
  }

  @media (max-width: 1300px) {
    gap: 2rem;
  }

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const EventCard = styled(Card)`
  max-width: 48rem;
  min-width: 25rem;
  height: 100%;
  position: relative;

  @media (max-width: 900px) {
    &:not(:first-of-type) {
      margin-top: 4rem;
    }
  }
`;

export const ImageWrapper = styled.div`
  width: calc(100% + 8rem);
  height: 25rem;
  margin: -4rem -4rem 0 -4rem;
  position: relative;

  @media (max-width: 900px) {
    height: 20rem;
  }
`;

export const EventImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1a1a;
  opacity: 0.4;
`;

export const SignupsDisabledWrapper = styled.div`
  position: absolute;
  top: 2rem;
  left: 0;
  background-color: ${(props) => props.theme.colors.white};
  padding: 1rem 2rem;
`;

export const SignupsDisabledText = styled.p`
  color: ${(props) => props.theme.colors.primary};
  text-transform: uppercase;
  font-weight: bold;
`;

export const EventTitle = styled.div`
  margin-top: 4rem;
  border-bottom: 0.2rem solid ${(props) => props.theme.colors.primary};
  padding-bottom: 1rem;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DetailsRow = styled.div`
  margin-top: 2rem;
`;

export const Label = styled.p`
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary};
`;

interface ButtonsWrapperProps {
  $withDetails: boolean;
}

export const ButtonsWrapper = styled.div<ButtonsWrapperProps>`
  display: flex;
  justify-content: ${(props) =>
    props.$withDetails ? 'space-between' : 'flex-end'};
  align-items: center;
`;

interface DetailsTextProps {
  $last?: boolean;
}

export const DetailsText = styled(Text)<DetailsTextProps>`
  font-weight: bold;
  text-transform: uppercase;
  min-height: ${(props) => (props.$last ? '6rem' : 0)};

  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
`;

// SIGN UP FORM
export const FormWrapper = styled.div`
  margin-top: 4rem;
`;

export const InputLabel = styled.label`
  font-size: 1.6rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
`;

interface TextInputProps {
  disabled?: boolean;
}

export const TextInput = styled.input<TextInputProps>`
  display: block;
  width: 100%;
  padding: 1.5rem 2rem;
  font-size: 1.6rem;
  font-family: inherit;
  margin-top: 0.5rem;
  border: 0.1rem solid ${(props) => props.theme.colors.primary};
  outline: none;

  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.gray.dark};
      border-color: ${props.theme.colors.gray.light};
      cursor: not-allowed;
    `}
`;

export const PhoneInput = styled(InputMask)<TextInputProps>`
  display: block;
  width: 100%;
  padding: 1.5rem 2rem;
  font-size: 1.6rem;
  font-family: inherit;
  margin-top: 0.5rem;
  border: 0.1rem solid ${(props) => props.theme.colors.primary};
  outline: none;

  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.gray.dark};
      border-color: ${props.theme.colors.gray.light};
      cursor: not-allowed;
    `}
`;

export const HelperText = styled.p`
  font-size: 1.2rem;
  margin-top: 0.5rem;
  display: none;
`;

interface InputRowProps {
  $twoElements?: boolean;
}

export const Row = styled.div<InputRowProps>`
  margin-top: 2rem;
  height: 100%;

  ${(props) =>
    props.$twoElements &&
    css`
      display: flex;
      align-items: flex-start;

      & > div {
        width: 100%;

        &:first-of-type {
          margin-right: 0.5rem;
        }

        &:last-of-type {
          margin-left: 0.5rem;
        }
      }
    `}
`;

interface RowProps {
  $error?: boolean;
}

export const InputWrapper = styled.div<RowProps>`
  ${(props) =>
    props.$error &&
    css`
      ${TextInput} {
        border-color: ${props.theme.colors.error.main};
      }

      ${HelperText} {
        display: block;
        color: ${props.theme.colors.error.main};
      }
    `}
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: end;
  margin-top: 4rem;
`;

export const LinearLoaderWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: -2rem;
`;

export const FilteringContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const FiltersLabel = styled.p`
  display: block;

  @media (max-width: 900px) {
    display: none;
  }
`;
