import {
  StyledFooter,
  Content,
  ContactInfo,
  SocialMenu,
  Logo,
  FooterText,
  AddressElement,
} from './styled';
import { MenuLink } from 'theme/GlobalStyles';
import LogoImage from 'assets/images/logo.svg';
import { useAppSelector } from 'redux/hooks';
import { selectMobile } from 'redux/App/slice';

export default function Footer() {
  const mobile = useAppSelector(selectMobile);

  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>
      <Content>
        <ContactInfo>
          <Logo src={LogoImage} alt="Infamous logo" />

          <AddressElement href="tel:+48666600556">
            +48 666 600 556 &#8226;
          </AddressElement>
          <AddressElement
            href="mailto:infms.events@gmail.com"
            style={{ marginLeft: '0.5rem' }}
          >
            infms.events@gmail.com
          </AddressElement>
        </ContactInfo>

        {!mobile && (
          <FooterText>
            &#169; {currentYear} &#8226; Infamous skydiving team
          </FooterText>
        )}

        <SocialMenu>
          <MenuLink
            $dark
            href="https://www.instagram.com/infamous_skydiving_team/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Instagram
          </MenuLink>
          <MenuLink
            $dark
            href="https://www.facebook.com/InfamousFreefly"
            target="_blank"
            rel="noreferrer noopener"
          >
            Facebook
          </MenuLink>
          <MenuLink $dark>Youtube</MenuLink>
        </SocialMenu>

        {mobile && (
          <FooterText>
            &#169; {currentYear} &#8226; Infamous skydiving team
          </FooterText>
        )}
      </Content>
    </StyledFooter>
  );
}
