import { Editor, Element, Transforms } from 'slate';

export type ListType = 'bulleted-list' | 'numbered-list';

export const CustomEditor = {
  isBoldMarkActive(editor: Editor) {
    const marks = Editor.marks(editor);
    return marks ? marks.bold === true : false;
  },

  isItalicMarkActive(editor: Editor) {
    const marks = Editor.marks(editor);
    return marks ? marks.italic === true : false;
  },

  isUnderlineMarkActive(editor: Editor) {
    const marks = Editor.marks(editor);
    return marks ? marks.underline === true : false;
  },

  toggleBoldMark(editor: Editor) {
    const isActive = CustomEditor.isBoldMarkActive(editor);
    if (isActive) {
      Editor.removeMark(editor, 'bold');
    } else {
      Editor.addMark(editor, 'bold', true);
    }
  },

  toggleItalicMark(editor: Editor) {
    const isActive = CustomEditor.isItalicMarkActive(editor);
    if (isActive) {
      Editor.removeMark(editor, 'italic');
    } else {
      Editor.addMark(editor, 'italic', true);
    }
  },

  toggleUnderlineMark(editor: Editor) {
    const isActive = CustomEditor.isUnderlineMarkActive(editor);
    if (isActive) {
      Editor.removeMark(editor, 'underline');
    } else {
      Editor.addMark(editor, 'underline', true);
    }
  },

  toggleMark(mark: string, editor: Editor) {
    switch (mark) {
      case 'bold':
        CustomEditor.toggleBoldMark(editor);
        break;
      case 'italic':
        CustomEditor.toggleItalicMark(editor);
        break;
      case 'underline':
        CustomEditor.toggleUnderlineMark(editor);
        break;
      default:
        break;
    }
  },

  isListActive(editor: Editor, type: ListType) {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === type,
      }),
    );

    return !!match;
  },

  toggleList(editor: Editor, type: ListType) {
    const isActive = CustomEditor.isListActive(editor, type);

    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === type,
      split: true,
    });

    const newProperties: Partial<Element> = {
      type: isActive ? 'paragraph' : 'list-item',
    };

    Transforms.setNodes<Element>(editor, newProperties);

    if (!isActive) {
      Transforms.wrapNodes(editor, { type, children: [] });
    }
  },
};
