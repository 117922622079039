import styled from 'styled-components';
import { Container, Title } from 'theme/GlobalStyles';

export const SocialMediaWrapper = styled.section`
  margin-top: 10rem;
`;

export const Content = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SocialMediaTitle = styled(Title)`
  margin-bottom: 5rem;
`;

export const Posts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  flex-wrap: unset;
  overflow-x: unset;

  @media (max-width: 900px) {
    width: calc(100% + 4rem);
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    margin-left: -4rem;
    margin-right: -4rem;
    padding-left: 2rem;
    padding-right: 2rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Post = styled.img`
  width: 38rem;
  height: 38rem;
  object-fit: cover;
  transition: opacity 0.2s ease-in-out;
  flex: unset;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1250px) {
    width: 30rem;
    height: 30rem;
  }

  @media (max-width: 900px) {
    width: 30rem;
    height: 30rem;
    flex: 0 0 auto;
  }
`;
