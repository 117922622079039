import { configureStore } from '@reduxjs/toolkit';
import eventsReducer from './events/slice';
import authReducer from './auth/slice';
import footageReducer from './footage/slice';
import participantsReducer from './participants/slice';
import appReducer from './App/slice';

const store = configureStore({
  reducer: {
    events: eventsReducer,
    auth: authReducer,
    footage: footageReducer,
    participants: participantsReducer,
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
