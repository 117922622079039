import { useEffect, useState } from 'react';
import BottomBar from '../BottomBar';
import HeroImageSource from 'assets/images/hero-image.jpg';
import { resizeText } from 'utils/helperFunctions';

import {
  HeroContainer,
  HeroImage,
  DarkOverlay,
  HeroTitleContainer,
  TitleWrapper,
  AnimatedTitleWrapper,
  HeroTitle,
  HeroOutlinedTitle,
  MobileHeroTitle,
} from './styled';
import { useAppSelector } from 'redux/hooks';
import { selectMobile } from 'redux/App/slice';

export default function HeroSection() {
  const [titleHidden, setTitleHidden] = useState(true);
  const mobile = useAppSelector(selectMobile);

  const handleTextResize = () => {
    resizeText({
      elements: document.querySelectorAll('.hero-text'),
      noOffset: window.innerWidth < 1140,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      // Resize on first page load
      handleTextResize();
      setTitleHidden(false);

      window.addEventListener('resize', handleTextResize);
    }, 100);

    return () => window.removeEventListener('resize', handleTextResize);
  }, []);

  return (
    <HeroContainer>
      <HeroImage src={HeroImageSource} alt="Infamous skydiving team" />
      <DarkOverlay />

      <HeroTitleContainer>
        {mobile ? (
          <MobileHeroTitle>Infamous skydiving team</MobileHeroTitle>
        ) : (
          <>
            <TitleWrapper>
              <HeroTitle className="hero-text" $hidden={titleHidden}>
                Infamous skydiving team
              </HeroTitle>
            </TitleWrapper>

            <AnimatedTitleWrapper $num={1}>
              <HeroOutlinedTitle className="hero-text">
                Infamous skydiving team
              </HeroOutlinedTitle>
            </AnimatedTitleWrapper>

            <AnimatedTitleWrapper $num={2}>
              <HeroOutlinedTitle className="hero-text">
                Infamous skydiving team
              </HeroOutlinedTitle>
            </AnimatedTitleWrapper>

            <AnimatedTitleWrapper $num={3}>
              <HeroOutlinedTitle className="hero-text">
                Infamous skydiving team
              </HeroOutlinedTitle>
            </AnimatedTitleWrapper>
          </>
        )}
      </HeroTitleContainer>
      <BottomBar />
    </HeroContainer>
  );
}
