import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { useSlate } from 'slate-react';
import { CustomEditor } from 'components/Dashboard/RichTextEditor/CustomEditor';

import { Toolbar as StyledToolbar, ToolbarButton, ButtonGroup } from './styled';

export default function Toolbar() {
  const editor = useSlate();

  return (
    <StyledToolbar>
      <ButtonGroup>
        <ToolbarButton
          type="button"
          $first
          $isActive={CustomEditor.isBoldMarkActive(editor)}
          onClick={() => CustomEditor.toggleBoldMark(editor)}
        >
          <FormatBoldIcon />
        </ToolbarButton>

        <ToolbarButton
          type="button"
          $isActive={CustomEditor.isItalicMarkActive(editor)}
          onClick={() => CustomEditor.toggleItalicMark(editor)}
        >
          <FormatItalicIcon />
        </ToolbarButton>

        <ToolbarButton
          type="button"
          $isActive={CustomEditor.isUnderlineMarkActive(editor)}
          onClick={() => CustomEditor.toggleUnderlineMark(editor)}
        >
          <FormatUnderlinedIcon />
        </ToolbarButton>
      </ButtonGroup>

      <ButtonGroup>
        <ToolbarButton
          type="button"
          $isActive={CustomEditor.isListActive(editor, 'bulleted-list')}
          onClick={() => CustomEditor.toggleList(editor, 'bulleted-list')}
        >
          <FormatListBulletedIcon />
        </ToolbarButton>

        <ToolbarButton
          type="button"
          $isActive={CustomEditor.isListActive(editor, 'numbered-list')}
          onClick={() => CustomEditor.toggleList(editor, 'numbered-list')}
        >
          <FormatListNumberedIcon />
        </ToolbarButton>
      </ButtonGroup>
    </StyledToolbar>
  );
}
