import HeroSection from './HeroSection';
import AboutUs from './AboutUs';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import SocialMedia from './SocialMedia';

export default function LandingPage() {
  return (
    <>
      <Navbar />

      <HeroSection />
      <AboutUs />
      <SocialMedia />

      <Footer />
    </>
  );
}
