import { PaginationWrapper, ArrowIcon } from './styled';
import { Text } from 'theme/GlobalStyles';

interface PaginationProps {
  onNextPageClick: () => void;
  onPrevPageClick: () => void;
  onChangePage: (newPage: number) => void;
  page: number;
  pageSize: number;
  documentsNumber: number;
  totalDocuments: number;
}

export default function Pagination({
  onNextPageClick,
  onPrevPageClick,
  onChangePage,
  page,
  pageSize,
  documentsNumber,
  totalDocuments,
}: PaginationProps) {
  const renderPaginationText = () => {
    if (page === 0) return `1-${pageSize} of ${totalDocuments}`;

    if (documentsNumber < pageSize)
      return `${totalDocuments}-${totalDocuments} of ${totalDocuments}`;

    const leftSide = page * pageSize + 1;
    const rightSide = leftSide + pageSize - 1;

    return `${leftSide}-${rightSide} of ${totalDocuments}`;
  };

  const nextPageDisabled = documentsNumber < pageSize;
  const prevPageDisabled = page === 0;

  const handleNextPageClick = () => {
    if (nextPageDisabled) return;

    onChangePage(page + 1);
    onNextPageClick();
  };

  const handlePrevPageClick = () => {
    if (prevPageDisabled) return;

    onChangePage(page - 1);
    onPrevPageClick();
  };

  return (
    <PaginationWrapper>
      <ArrowIcon
        $disabled={prevPageDisabled}
        $left
        onClick={handlePrevPageClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.447"
          height="13.092"
          viewBox="0 0 17.447 13.092"
        >
          <path
            d="M170.915,346.092l-1.17-1.143,4.573-4.573H160v-1.633h14.317l-4.6-4.6,1.17-1.143,6.56,6.56Z"
            transform="translate(177.447 346.092) rotate(180)"
          />
        </svg>
      </ArrowIcon>

      <Text>{renderPaginationText()}</Text>

      <ArrowIcon $disabled={nextPageDisabled} onClick={handleNextPageClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.447"
          height="13.092"
          viewBox="0 0 17.447 13.092"
        >
          <path
            d="M170.915,346.092l-1.17-1.143,4.573-4.573H160v-1.633h14.317l-4.6-4.6,1.17-1.143,6.56,6.56Z"
            transform="translate(-160 -333)"
          />
        </svg>
      </ArrowIcon>
    </PaginationWrapper>
  );
}
