import { Event } from 'api/models/Event';
import { db, storage } from 'api/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  serverTimestamp,
  writeBatch,
  query,
  orderBy,
  startAfter,
  limit,
  endBefore,
  limitToLast,
  Query,
  DocumentData,
  where,
  DocumentSnapshot,
  setDoc,
  increment,
  deleteDoc,
  QueryConstraint,
  getCountFromServer,
} from 'firebase/firestore';
import { Participant } from 'api/models/Participant';
import { fetchParticipantsByIds } from './participant';
import { calculateDateQuery } from 'utils/helperFunctions';
import { Mail } from 'api/models/Mail';

const eventsCollection = collection(db, 'events');
const mailCollection = collection(db, 'mail');

export const fetchEvents = async (
  pageSize: number,
  selectedMonth?: string,
  selectedYear?: string,
) => {
  const { startDate, endDate } = calculateDateQuery(
    selectedMonth,
    selectedYear,
  );

  let dateQuery: QueryConstraint[] = [];
  if (startDate && endDate) {
    dateQuery = [
      where('date.start', '>=', startDate),
      where('date.start', '<', endDate),
    ];
  }

  const countSnapshot = await getCountFromServer(
    query(eventsCollection, ...dateQuery),
  );
  const totalDocuments = countSnapshot.data().count;

  try {
    const q = query(
      eventsCollection,
      ...dateQuery,
      orderBy('date.start', 'asc'),
      limit(pageSize),
    );
    const snapshot = await getDocs(q);

    const events = snapshot.docs.map((eventDoc) => ({
      ...eventDoc.data(),
      id: eventDoc.id,
      date: {
        start: eventDoc.data().date.start.toDate(),
        end: eventDoc.data().date.end.toDate(),
      },
    })) as Event[];

    // prepare next query (pagination)
    let nextQuery: Query<DocumentData> | undefined;
    if (events.length < totalDocuments) {
      const lastEvent = snapshot.docs[snapshot.docs.length - 1];

      nextQuery = query(
        eventsCollection,
        ...dateQuery,
        orderBy('date.start', 'asc'),
        startAfter(lastEvent),
        limit(pageSize),
      );
    }

    return { events, totalDocuments, nextQuery };
  } catch (error) {
    console.error(`Error while fetching events: ${error}`);
  }
};

export const fetchNextPageEvents = async (
  next: Query<DocumentData>,
  page: number,
  pageSize: number,
  totalDocuments: number,
  selectedMonth?: string,
  selectedYear?: string,
) => {
  const { startDate, endDate } = calculateDateQuery(
    selectedMonth,
    selectedYear,
  );

  let dateQuery: QueryConstraint[] = [];
  if (startDate && endDate) {
    dateQuery = [
      where('date.start', '>=', startDate),
      where('date.start', '<', endDate),
    ];
  }

  try {
    const snapshot = await getDocs(next);

    const events = snapshot.docs.map((eventDoc) => ({
      ...eventDoc.data(),
      id: eventDoc.id,
      date: {
        start: eventDoc.data().date.start.toDate(),
        end: eventDoc.data().date.end.toDate(),
      },
    })) as Event[];

    // prepare next and prev query (pagination)
    let nextQuery: Query<DocumentData> | undefined;
    if (pageSize * page + events.length < totalDocuments) {
      const lastEvent = snapshot.docs[snapshot.docs.length - 1];

      nextQuery = query(
        eventsCollection,
        ...dateQuery,
        orderBy('date.start', 'asc'),
        startAfter(lastEvent),
        limit(pageSize),
      );
    }

    const firstEvent = snapshot.docs[0];
    const prevQuery = query(
      eventsCollection,
      ...dateQuery,
      orderBy('date.start', 'asc'),
      endBefore(firstEvent),
      limitToLast(pageSize),
    );

    return { events, nextQuery, prevQuery };
  } catch (error) {
    console.error(`Error while fetching events: ${error}`);
  }
};

export const fetchPrevPageEvents = async (
  prev: Query<DocumentData>,
  page: number,
  pageSize: number,
  selectedMonth?: string,
  selectedYear?: string,
) => {
  const { startDate, endDate } = calculateDateQuery(
    selectedMonth,
    selectedYear,
  );

  let dateQuery: QueryConstraint[] = [];
  if (startDate && endDate) {
    dateQuery = [
      where('date.start', '>=', startDate),
      where('date.start', '<', endDate),
    ];
  }

  try {
    const snapshot = await getDocs(prev);

    const events = snapshot.docs.map((eventDoc) => ({
      ...eventDoc.data(),
      id: eventDoc.id,
      date: {
        start: eventDoc.data().date.start.toDate(),
        end: eventDoc.data().date.end.toDate(),
      },
    })) as Event[];

    // prepare next and prev query (pagination)
    let prevQuery: Query<DocumentData> | undefined;
    if (page > 0) {
      const firstEvent = snapshot.docs[0];

      prevQuery = query(
        eventsCollection,
        ...dateQuery,
        orderBy('date.start', 'asc'),
        endBefore(firstEvent),
        limitToLast(pageSize),
      );
    }

    const lastEvent = snapshot.docs[snapshot.docs.length - 1];

    const nextQuery = query(
      eventsCollection,
      ...dateQuery,
      orderBy('date.start', 'asc'),
      startAfter(lastEvent),
      limit(pageSize),
    );

    return { events, nextQuery, prevQuery };
  } catch (error) {
    console.error(`Error while fetching events: ${error}`);
  }
};

export const fetchEventById = async (eventId: string) => {
  try {
    const eventRef = doc(db, 'events', eventId);
    const snapshot = await getDoc(eventRef);

    if (!snapshot.exists()) {
      throw new Error('404 event not found');
    }

    const eventData = snapshot.data();
    const event = {
      ...eventData,
      id: eventId,
      date: {
        start: eventData.date.start.toDate(),
        end: eventData.date.end.toDate(),
      },
    } as Event;

    return event;
  } catch (error) {
    console.error(`Error while fetching event by id: ${error}`);
  }
};

export const addEvent = async (event: Event, eventImage: File) => {
  try {
    // upload event image
    const storageRef = ref(
      storage,
      `eventImages/${event.name}-${new Date().getTime()}`,
    );
    const uploadedImage = await uploadBytes(storageRef, eventImage);
    const publicUrl = await getDownloadURL(storageRef);

    const eventRef = await addDoc(eventsCollection, {
      ...event,
      imageUrl: publicUrl,
      imagePath: uploadedImage.ref.fullPath,
      createdAt: serverTimestamp(),
    });

    const addedEvent = await getDoc(eventRef);

    return {
      ...addedEvent.data(),
      id: addedEvent.id,
      date: {
        start: addedEvent.data()?.date.start.toDate(),
        end: addedEvent.data()?.date.end.toDate(),
      },
    } as Event;
  } catch (error) {
    console.error(`Error while adding event: ${error}`);
  }
};

export const updateEvent = async ({
  eventId,
  newEvent,
  eventImage,
}: {
  eventId: string;
  newEvent: Event;
  eventImage?: File | null;
}) => {
  try {
    // Update image
    let imagePath = '';
    let publicUrl = '';

    if (eventImage) {
      const storageRef = ref(storage, newEvent.imagePath);
      const uploadedImage = await uploadBytes(storageRef, eventImage);
      publicUrl = await getDownloadURL(storageRef);
      imagePath = uploadedImage.ref.fullPath;
    }

    const eventDoc =
      imagePath && publicUrl
        ? { ...newEvent, imagePath, imageUrl: publicUrl }
        : { ...newEvent };

    const eventRef = doc(db, 'events', eventId);
    await updateDoc(eventRef, eventDoc);

    const updatedEvent = await getDoc(eventRef);
    return {
      ...updatedEvent.data(),
      id: updatedEvent.id,
      date: {
        start: updatedEvent.data()?.date.start.toDate(),
        end: updatedEvent.data()?.date.end.toDate(),
      },
    } as Event;
  } catch (error) {
    console.error(`Error while updating event: ${error}`);
  }
};

export const changeEventSignupsStatus = async ({
  eventIds,
  signupsBlocked,
}: {
  eventIds: string[];
  signupsBlocked: boolean;
}) => {
  const batch = writeBatch(db);

  try {
    eventIds.forEach((eventId) => {
      const eventRef = doc(db, 'events', eventId);
      batch.update(eventRef, { signupsBlocked });
    });

    await batch.commit();

    return { eventIds, signupsBlocked };
  } catch (error) {
    console.error(`Error while updating event: ${error}`);
  }
};

export const deleteEvents = async (eventIds: string[]) => {
  const batch = writeBatch(db);

  try {
    eventIds.forEach(async (eventId) => {
      const eventRef = doc(db, 'events', eventId);
      batch.delete(eventRef);

      // Delete many-to-many relations between event and participant
      const junctionRef = collection(db, `junction_event_participant`);
      const q = query(junctionRef, where('eventId', '==', eventId));
      await getDocs(q).then((snapshot) => {
        snapshot.forEach((junctionDoc) => {
          batch.delete(junctionDoc.ref);
        });
      });
    });

    await batch.commit();

    return eventIds;
  } catch (error) {
    console.error(`Error while deleting event: ${error}`);
  }
};

export const signupToEvent = async ({
  eventId,
  participant,
}: {
  eventId: string;
  participant: Participant;
}): Promise<{ status: number; event: Event | undefined }> => {
  try {
    // check if a participant with this email address exists
    const q = query(
      collection(db, 'participants'),
      where('email', '==', participant.email),
    );
    const participantsSnapshot = await getDocs(q);

    let newParticipant: DocumentSnapshot<DocumentData>;

    // if not create one
    if (participantsSnapshot.empty) {
      const participantRef = await addDoc(
        collection(db, 'participants'),
        participant,
      );
      newParticipant = await getDoc(participantRef);
      // else update existing user
    } else {
      const participantRef = doc(
        db,
        'participants',
        participantsSnapshot.docs[0].id,
      );
      await updateDoc(participantRef, participant);
      newParticipant = await getDoc(participantRef);
    }

    // Add new many-to-many relation between event and participant
    const junctionRef = doc(
      db,
      `junction_event_participant/${eventId}_${newParticipant.id}`,
    );
    const junctionSnapshot = await getDoc(junctionRef);

    if (junctionSnapshot.exists()) {
      return { status: 409, event: undefined };
    }

    await setDoc(junctionRef, {
      eventId,
      participantId: newParticipant.id,
      signUpDate: serverTimestamp(),
    });

    // Update participants number in the event document
    await updateDoc(doc(db, 'events', eventId), {
      participantsNumber: increment(1),
    });

    const updatedEvent = await getDoc(doc(db, 'events', eventId));

    // Create mail document that will trigger a cloud function to send an email
    const mailDocument: Mail = {
      to: [participant.email],
      from: 'Infamous Skydiving Team <team@infamouskydiving.com>',
      message: {
        subject: 'Thank you for registration',
        html: `Dziękujemy za rejestrację na camp. Do zobaczenia na lotnisku !
        <br /><br />
        Thank you for registration. See you soon at the DZ.
        <br /><br />
        Infamous Skydiving Team`,
      },
    };

    await addDoc(mailCollection, mailDocument);

    return {
      status: 200,
      event: {
        ...updatedEvent.data(),
        id: updatedEvent.id,
        date: {
          start: updatedEvent.data()?.date.start.toDate(),
          end: updatedEvent.data()?.date.end.toDate(),
        },
      } as Event,
    };
  } catch (error) {
    return { status: 500, event: undefined };
  }
};

export const signOffEvent = async ({
  eventId,
  participantId,
}: {
  eventId: string;
  participantId: string;
}) => {
  try {
    // Remove many-to-many relation between event and participant
    const junctionRef = doc(
      db,
      `junction_event_participant/${eventId}_${participantId}`,
    );
    await deleteDoc(junctionRef);

    // Decrease participants number in the event document
    const eventRef = doc(db, 'events', eventId);
    await updateDoc(eventRef, {
      participantsNumber: increment(-1),
    });

    const updatedEvent = await getDoc(eventRef);
    return {
      ...updatedEvent.data(),
      id: updatedEvent.id,
      date: {
        start: updatedEvent.data()?.date.start.toDate(),
        end: updatedEvent.data()?.date.end.toDate(),
      },
    } as Event;
  } catch (error) {
    console.error(`Error while signing off an event: ${error}`);
  }
};

export const fetchEventParticipants = async (eventId: string) => {
  try {
    const junctionRef = collection(db, `junction_event_participant`);
    const q = query(
      junctionRef,
      where('eventId', '==', eventId),
      orderBy('signUpDate', 'asc'),
    );
    const snapshot = await getDocs(q);

    const junctionParticipants = snapshot.docs.map((document) => {
      return {
        id: document.data().participantId as string,
        signUpDate: document.data().signUpDate.toDate(),
      };
    });

    const participants = await fetchParticipantsByIds(junctionParticipants);
    return participants;
  } catch (error) {
    console.error(`Error while fetching event participants: ${error}`);
  }
};
