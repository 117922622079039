import { Footage } from 'api/models/Footage';
import { db } from 'api/firebase';
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  writeBatch,
  query,
  orderBy,
  startAfter,
  limit,
  getCountFromServer,
  endBefore,
  limitToLast,
  Query,
  DocumentData,
} from 'firebase/firestore';

const footageCollection = collection(db, 'footage');

export const fetchFootage = async (pageSize: number) => {
  try {
    const countSnapshot = await getCountFromServer(footageCollection);
    const totalDocuments = countSnapshot.data().count;

    const q = query(
      footageCollection,
      orderBy('date', 'desc'),
      limit(pageSize),
    );

    const snapshot = await getDocs(q);

    const footage = snapshot.docs.map((footageDoc) => ({
      ...footageDoc.data(),
      id: footageDoc.id,
      date: footageDoc.data().date.toDate(),
    })) as Footage[];

    // prepare next query (pagination)
    let nextQuery: Query<DocumentData> | undefined;
    if (footage.length < totalDocuments) {
      const lastFootage = snapshot.docs[snapshot.docs.length - 1];

      nextQuery = query(
        footageCollection,
        orderBy('date', 'desc'),
        startAfter(lastFootage),
        limit(pageSize),
      );
    }

    return { footage, totalDocuments, nextQuery };
  } catch (error) {
    console.error(`Error while fetching footage: ${error}`);
  }
};

export const fetchNextPageFootage = async (
  next: Query<DocumentData>,
  page: number,
  pageSize: number,
  totalDocuments: number,
) => {
  try {
    const snapshot = await getDocs(next);

    const footage = snapshot.docs.map((footageDoc) => ({
      ...footageDoc.data(),
      id: footageDoc.id,
      date: footageDoc.data().date.toDate(),
    })) as Footage[];

    // prepare next and prev query (pagination)
    let nextQuery: Query<DocumentData> | undefined;
    if (pageSize * page + footage.length < totalDocuments) {
      const lastFootage = snapshot.docs[snapshot.docs.length - 1];

      nextQuery = query(
        footageCollection,
        orderBy('date', 'desc'),
        startAfter(lastFootage),
        limit(pageSize),
      );
    }

    const firstFootage = snapshot.docs[0];
    const prevQuery = query(
      footageCollection,
      orderBy('date', 'desc'),
      endBefore(firstFootage),
      limitToLast(pageSize),
    );

    return { footage, nextQuery, prevQuery };
  } catch (error) {
    console.error(`Error while fetching footage: ${error}`);
  }
};

export const fetchPrevPageFootage = async (
  prev: Query<DocumentData>,
  page: number,
  pageSize: number,
) => {
  try {
    const snapshot = await getDocs(prev);

    const footage = snapshot.docs.map((footageDoc) => ({
      ...footageDoc.data(),
      id: footageDoc.id,
      date: footageDoc.data().date.toDate(),
    })) as Footage[];

    // prepare next and prev query (pagination)
    let prevQuery: Query<DocumentData> | undefined;
    if (page > 0) {
      const firstFootage = snapshot.docs[0];

      prevQuery = query(
        footageCollection,
        orderBy('date', 'desc'),
        endBefore(firstFootage),
        limitToLast(pageSize),
      );
    }

    const lastFootage = snapshot.docs[snapshot.docs.length - 1];

    const nextQuery = query(
      footageCollection,
      orderBy('date', 'desc'),
      startAfter(lastFootage),
      limit(pageSize),
    );

    return { footage, nextQuery, prevQuery };
  } catch (error) {
    console.error(`Error while fetching footage: ${error}`);
  }
};

export const addFootage = async (footage: Footage) => {
  try {
    const footageRef = await addDoc(footageCollection, footage);
    const addedFootage = await getDoc(footageRef);

    return {
      ...addedFootage.data(),
      id: addedFootage.id,
      date: addedFootage.data()?.date.toDate(),
    } as Footage;
  } catch (error) {
    console.error(`Error while adding footage: ${error}`);
  }
};

export const updateFootage = async ({
  footageId,
  newFootage,
}: {
  footageId: string;
  newFootage: Footage;
}) => {
  try {
    const footageRef = doc(db, 'footage', footageId);
    await updateDoc(footageRef, { ...newFootage });

    const updatedFootage = await getDoc(footageRef);
    return {
      ...updatedFootage.data(),
      id: updatedFootage.id,
      date: updatedFootage.data()?.date.toDate(),
    } as Footage;
  } catch (error) {
    console.error(`Error while updating footage: ${error}`);
  }
};

export const deleteFootage = async (footageIds: string[]) => {
  const batch = writeBatch(db);

  try {
    footageIds.forEach((footageId) => {
      const footageRef = doc(db, 'footage', footageId);
      batch.delete(footageRef);
    });

    await batch.commit();

    return footageIds;
  } catch (error) {
    console.error(`Error while deleting footage: ${error}`);
  }
};
