import styled, { keyframes } from 'styled-components';

export const BottomBarWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

export const Content = styled.div`
  width: 100%;
  height: 15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media (max-width: 900px) {
    align-items: flex-start;
    height: 20rem;
  }

  @media (max-height: 700px) {
    height: 100%;
    margin-bottom: 2rem;
  }
`;

export const SocialMenu = styled.ul`
  display: flex;
  align-items: center;
  gap: 3rem;
`;

export const MenuItem = styled.li`
  list-style: none;
`;

const rotate = keyframes`
  0% {
    transform: translateY(-1rem);
  }

  50% {
    transform: translateY(2.5rem);
  }

  100% {
    transform: translateY(-1rem);
  }
`;

export const Mouse = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 50%;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 5rem;
  margin: 0 auto;

  animation: ${rotate} 1.3s ease-in-out infinite;

  @media (max-height: 700px) {
    display: none;
  }
`;
