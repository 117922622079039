import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectFootage,
  selectIsLoading,
  selectError,
  selectNextQuery,
  selectPrevQuery,
  selectTotalDocuments,
} from 'redux/footage/selectors';
import {
  fetchFootage,
  fetchNextPageFootage,
  fetchPrevPageFootage,
} from 'redux/footage/thunk';
import { Title, Subtitle, Text } from 'theme/GlobalStyles';
import Alert from 'components/Alert';
import { renderDateRange } from 'utils/helperFunctions';

import {
  PageWrapper,
  Content,
  Header,
  FootageWrapper,
  FootageTitle,
  Details,
  ImageWrapper,
  Image,
  DarkOverlay,
  Footage,
  LoaderWrapper,
  PlayButton,
  StyledIframe,
  VideoWrapper,
} from './styled';
import Loader from 'components/Loader';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Popup from 'components/Popup';
import { Footage as FootageModel } from 'api/models/Footage';
import Pagination from 'components/Pagination';

const rowsPerPage = 6;

export default function FootagePage() {
  const dispatch = useAppDispatch();

  const footage = useAppSelector(selectFootage);
  const isLoading = useAppSelector(selectIsLoading);
  const error = useAppSelector(selectError);
  const nextQuery = useAppSelector(selectNextQuery);
  const prevQuery = useAppSelector(selectPrevQuery);
  const totalDocuments = useAppSelector(selectTotalDocuments);
  const [page, setPage] = useState(0);
  const [selectedFootage, setSelectedFootage] = useState<
    FootageModel | undefined
  >(undefined);

  useEffect(() => {
    dispatch(fetchFootage(rowsPerPage));
  }, []);

  const renderedContent = () => {
    if (isLoading) {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }

    if (error) {
      return <Alert type="error">Oops! Something went wrong: {error}</Alert>;
    }

    if (footage.length === 0) {
      return <Text style={{ marginTop: '2rem' }}>No footage</Text>;
    }

    return (
      <FootageWrapper>
        {footage.map((f) => (
          <Footage key={f.id}>
            <ImageWrapper onClick={() => setSelectedFootage(f)}>
              <Image src={f.thumbnailUrl} alt={f.name} />
              <DarkOverlay />
              <PlayButton
                xmlns="http://www.w3.org/2000/svg"
                width="52.131"
                height="52.131"
                viewBox="0 0 52.131 52.131"
              >
                <path
                  d="M98.987,211.718l16.731-10.653L98.987,190.413Zm6.078,14.413a24.253,24.253,0,0,1-9.713-1.974,25.254,25.254,0,0,1-13.379-13.379,25.038,25.038,0,0,1,0-19.488,25.05,25.05,0,0,1,5.389-7.958,25.694,25.694,0,0,1,7.99-5.358,25.038,25.038,0,0,1,19.488,0,25.026,25.026,0,0,1,13.316,13.316,25.038,25.038,0,0,1,0,19.488,25.7,25.7,0,0,1-5.358,7.99,25.05,25.05,0,0,1-7.958,5.389A24.408,24.408,0,0,1,105.065,226.131ZM105.065,201.065Z"
                  transform="translate(-79 -175)"
                  fill="none"
                  strokeWidth="2"
                  className="playButton"
                />
              </PlayButton>
            </ImageWrapper>

            <Details>{renderDateRange({ start: f.date, end: f.date })}</Details>

            <FootageTitle>
              <Subtitle>{f.name}</Subtitle>
            </FootageTitle>
          </Footage>
        ))}
      </FootageWrapper>
    );
  };

  return (
    <PageWrapper>
      <Navbar dark />

      <Content>
        <Header>
          <Title>Footage</Title>
        </Header>

        {renderedContent()}

        {nextQuery && !error && !isLoading && (
          <Pagination
            page={page}
            pageSize={rowsPerPage}
            totalDocuments={totalDocuments}
            documentsNumber={footage.length}
            onChangePage={(newPage: number) => setPage(newPage)}
            onNextPageClick={() =>
              dispatch(
                fetchNextPageFootage({
                  nextQuery,
                  page,
                  pageSize: rowsPerPage,
                  totalDocuments,
                }),
              )
            }
            onPrevPageClick={() => {
              if (!prevQuery) return;

              dispatch(
                fetchPrevPageFootage({
                  prevQuery,
                  page,
                  pageSize: rowsPerPage,
                }),
              );
            }}
          />
        )}
      </Content>

      {selectedFootage && (
        <Popup
          title={selectedFootage.name}
          handleClose={() => setSelectedFootage(undefined)}
        >
          <VideoWrapper>
            <StyledIframe
              src={selectedFootage.embedUrl}
              title={selectedFootage.name}
            />
          </VideoWrapper>
        </Popup>
      )}

      <Footer />
    </PageWrapper>
  );
}
