import { useEffect } from 'react';
import { Formik } from 'formik';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { login } from 'redux/auth/thunk';
import { selectError, selectIsLoading, selectUser } from 'redux/auth/selectors';
import { useNavigate } from 'react-router-dom';
import theme from 'containers/Dashboard/theme';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LogoImage from 'assets/images/logo.svg';
import * as Yup from 'yup';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';

import { Wrapper, Content, Form, Logo } from './styled';

interface LoginFormValues {
  username: string;
  password: string;
}

export default function LoginPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectUser);
  const isLoading = useAppSelector(selectIsLoading);
  const error = useAppSelector(selectError);

  useEffect(() => {
    if (!user) return;

    navigate('/dashboard/footage');
  }, [user]);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('This field is required'),
    password: Yup.string().required('This field is required'),
  });

  const initialValues: LoginFormValues = {
    username: '',
    password: '',
  };

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Content>
          <Logo src={LogoImage} alt="Infamous" />

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => dispatch(login(values))}
          >
            {({ values, errors, touched, setFieldValue, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  label="Username"
                  type="text"
                  fullWidth
                  value={values.username}
                  onChange={(e) => setFieldValue('username', e.target.value)}
                  sx={{ marginBottom: '1rem' }}
                  error={Boolean(errors.username && touched.username)}
                  helperText={touched.username && errors.username}
                  disabled={isLoading}
                />

                <TextField
                  label="Password"
                  type="password"
                  fullWidth
                  value={values.password}
                  onChange={(e) => setFieldValue('password', e.target.value)}
                  sx={{ marginBottom: '2rem' }}
                  error={Boolean(errors.password && touched.password)}
                  helperText={touched.password && errors.password}
                  disabled={isLoading}
                />

                {isLoading && (
                  <LinearProgress
                    sx={{ marginBottom: '2rem', width: '100%' }}
                  />
                )}

                {error && (
                  <Alert
                    severity="error"
                    sx={{ width: '100%', marginBottom: '2rem' }}
                  >
                    {error}
                  </Alert>
                )}

                <Button variant="contained" type="submit" disabled={isLoading}>
                  Zaloguj się
                </Button>
              </Form>
            )}
          </Formik>
        </Content>
      </Wrapper>
    </ThemeProvider>
  );
}
