import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { MouseEvent, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import {
  selectParticipants,
  selectIsLoading,
  selectTotalDocuments,
  selectSortBy,
  selectSortByOrder,
  selectNextQuery,
  selectPrevQuery,
} from 'redux/participants/selectors';
import {
  fetchParticipants,
  fetchNextPageParticipants,
  fetchPrevPageParticipants,
} from 'redux/participants/thunk';
import Loader from 'components/Loader';
import TableToolbar from './TableToolbar';
import {
  SortByOrder,
  setSortBy,
  setSortByOrder,
} from 'redux/participants/slice';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Participant } from 'api/models/Participant';

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Imię',
  },
  {
    id: 'surname',
    numeric: false,
    disablePadding: false,
    label: 'Nazwisko',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'phoneNumber',
    numeric: false,
    disablePadding: false,
    label: 'Numer telefonu',
  },
  {
    id: 'numberOfJumps',
    numeric: false,
    disablePadding: false,
    label: 'Ilość skoków',
  },
  {
    id: 'tunnelHours',
    numeric: false,
    disablePadding: false,
    label: 'Godziny w tunelu',
  },
  {
    id: 'bellyTracking',
    numeric: false,
    disablePadding: false,
    label: 'Tracking on belly',
  },
  {
    id: 'backTracking',
    numeric: false,
    disablePadding: false,
    label: 'Tracking on back',
  },
  {
    id: 'headUpSkills',
    numeric: false,
    disablePadding: false,
    label: 'Flying skills in Head Up',
  },
  {
    id: 'headDownSkills',
    numeric: false,
    disablePadding: false,
    label: 'Flying skills in Head Down',
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof Participant,
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  numSelected: number;
  rowCount: number;
  sortBy: keyof Participant;
  sortByOrder: SortByOrder;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onRequestSort,
    onSelectAllClick,
    numSelected,
    rowCount,
    sortBy,
    sortByOrder,
  } = props;

  const createSortHandler =
    (property: keyof Participant) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'Zaznacz wszystkie',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={sortBy === headCell.id ? sortByOrder : false}
          >
            <TableSortLabel
              active={sortBy === headCell.id}
              direction={sortBy === headCell.id ? sortByOrder : SortByOrder.Asc}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function Participants() {
  const dispatch = useAppDispatch();

  const participants = useAppSelector(selectParticipants);
  const isLoading = useAppSelector(selectIsLoading);
  const totalDocuments = useAppSelector(selectTotalDocuments);
  const sortBy = useAppSelector(selectSortBy);
  const sortByOrder = useAppSelector(selectSortByOrder);
  const nextQuery = useAppSelector(selectNextQuery);
  const prevQuery = useAppSelector(selectPrevQuery);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(
      fetchParticipants({
        pageSize: rowsPerPage,
        sortBy: sortBy as string,
        sortByOrder,
      }),
    );
    setPage(0);
  }, [rowsPerPage, sortBy, sortByOrder]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = participants.map((participant) => participant.id!);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage > page && nextQuery) {
      dispatch(
        fetchNextPageParticipants({
          nextQuery,
          page,
          pageSize: rowsPerPage,
          sortBy: sortBy as string,
          sortByOrder,
          totalDocuments,
        }),
      );
    } else {
      if (!prevQuery) return;

      dispatch(
        fetchPrevPageParticipants({
          prevQuery,
          page,
          pageSize: rowsPerPage,
          sortBy: sortBy as string,
          sortByOrder,
        }),
      );
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof Participant,
  ) => {
    if (
      property === 'bellyTracking' ||
      property === 'backTracking' ||
      property === 'headUpSkills' ||
      property === 'headDownSkills'
    )
      return;

    const isAsc = sortBy === property && sortByOrder === SortByOrder.Asc;
    dispatch(setSortBy(property));
    dispatch(setSortByOrder(isAsc ? SortByOrder.Desc : SortByOrder.Asc));
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalDocuments) : 0;

  if (isLoading) return <Loader />;

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableToolbar numSelected={selected.length} />
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="footage table">
          <EnhancedTableHead
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
            numSelected={selected.length}
            rowCount={participants.length}
            sortBy={sortBy}
            sortByOrder={sortByOrder}
          />
          <TableBody>
            {participants.map((participant) => {
              const isItemSelected = isSelected(participant.id!);
              const labelId = `footage-${participant.id}-checkbox`;

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, participant.id!)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={participant.id}
                  selected={isItemSelected}
                >
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="checkbox"
                  >
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell>{participant.name}</TableCell>
                  <TableCell>{participant.surname}</TableCell>
                  <TableCell>{participant.email}</TableCell>
                  <TableCell>{participant.phoneNumber}</TableCell>
                  <TableCell>{participant.numberOfJumps}</TableCell>
                  <TableCell>{participant.tunnelHours}</TableCell>
                  <TableCell>{participant.trackingOnBelly}</TableCell>
                  <TableCell>{participant.trackingOnBack}</TableCell>
                  <TableCell>{participant.headUpSkills}</TableCell>
                  <TableCell>{participant.headDownSkills}</TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={totalDocuments}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
