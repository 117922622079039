import {
  PopupWrapper,
  DarkOverlay,
  StyledPopup,
  ExitButton,
  Header,
} from './styled';
import { Subtitle } from 'theme/GlobalStyles';

interface PopupProps {
  title: string;
  handleClose: () => void;
  small?: boolean;
  children: React.ReactNode;
}

export default function Popup({
  title,
  handleClose,
  small,
  children,
}: PopupProps) {
  return (
    <PopupWrapper>
      <DarkOverlay onClick={handleClose} />

      <StyledPopup $small={small}>
        <Header>
          <Subtitle>{title}</Subtitle>

          <ExitButton onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
            >
              <path
                d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z"
                transform="translate(-7.5 -7.5)"
              />
            </svg>
          </ExitButton>
        </Header>

        {children}
      </StyledPopup>
    </PopupWrapper>
  );
}
