import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { Formik, Form } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { useAppDispatch } from 'redux/hooks';
import { addFootage, updateFootage } from 'redux/footage/thunk';
import { Footage } from 'api/models/Footage';
import axios from 'axios';

import { StyledTextField, EventImage } from './styled';
import { Stack } from '@mui/material';

interface AddEventDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  selectedFootage?: Footage;
}

interface FormValues {
  name: string;
  date: Date | null;
  youtubeUrl: string;
}

export default function AddFootageDialog({
  isOpen,
  handleClose,
  selectedFootage,
}: AddEventDialogProps) {
  const dispatch = useAppDispatch();

  const [videoDetailsLoading, setVideoDetailsLoading] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [embedUrl, setEmbedUrl] = useState('');

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('To pole jest wymagane'),
    date: Yup.date()
      .typeError('Niepoprawna data')
      .required('To pole jest wymagane'),
    youtubeUrl: Yup.string().required('To pole jest wymagane'),
  });

  const initialValues: FormValues = selectedFootage
    ? {
        name: selectedFootage.name,
        date: selectedFootage.date,
        youtubeUrl: selectedFootage.youtubeUrl,
      }
    : {
        name: '',
        date: null,
        youtubeUrl: '',
      };

  const handleSubmit = (values: FormValues) => {
    if (!values.date || !thumbnailUrl || !embedUrl)
      return alert('Podaj wszystkie dane');

    const newFootage: Footage = {
      name: values.name,
      date: values.date,
      youtubeUrl: values.youtubeUrl,
      thumbnailUrl,
      embedUrl,
    };

    if (selectedFootage && selectedFootage.id) {
      dispatch(updateFootage({ footageId: selectedFootage.id, newFootage }));
    } else {
      dispatch(addFootage(newFootage));
    }
    handleClose();
  };

  const handleFetchVideoDetails = async (
    youtubeUrl: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    const youtubeApiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
    if (!youtubeApiKey || !youtubeUrl) return;

    setVideoDetailsLoading(true);

    const url = new URL(youtubeUrl);
    const searchParams = new URLSearchParams(url.search);
    const youtubeId = searchParams.get('v');
    const videoUrl = `https://www.youtube.com/embed/${youtubeId}`;

    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${youtubeId}&maxResults=1&key=${youtubeApiKey}`,
      );

      const name = response.data.items[0].snippet.title;
      const { thumbnails } = response.data.items[0].snippet;
      const thumbnail =
        thumbnails.maxres?.url ||
        thumbnails.high?.url ||
        thumbnails.standard?.url ||
        thumbnails.medium?.url ||
        thumbnails.default?.url;

      setFieldValue('name', name);
      setThumbnailUrl(thumbnail);
      setVideoDetailsLoading(false);
      setEmbedUrl(videoUrl);
    } catch (error) {
      setVideoDetailsLoading(false);
      alert('Youtube url invalid');
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {({ submitForm, isSubmitting, values, setFieldValue, errors }) => (
          <>
            <DialogTitle>Dodaj nowe nagranie</DialogTitle>
            <DialogContent>
              <Form>
                <Stack direction="row" alignItems="center">
                  <StyledTextField
                    name="youtubeUrl"
                    type="text"
                    label="Youtube URL"
                    value={values.youtubeUrl}
                    onChange={(event) =>
                      setFieldValue('youtubeUrl', event.target.value)
                    }
                    fullWidth
                    error={Boolean(errors.youtubeUrl)}
                    helperText={errors.youtubeUrl}
                    sx={{ marginTop: '1rem', marginRight: '1rem' }}
                    disabled={videoDetailsLoading || isSubmitting}
                  />

                  <Button
                    variant="text"
                    sx={{ flexShrink: 0 }}
                    onClick={() =>
                      handleFetchVideoDetails(values.youtubeUrl, setFieldValue)
                    }
                    disabled={videoDetailsLoading || isSubmitting}
                  >
                    Pobierz dane filmu
                  </Button>
                </Stack>

                <StyledTextField
                  name="name"
                  type="text"
                  label="Nazwa"
                  value={values.name}
                  onChange={(event) =>
                    setFieldValue('name', event.target.value)
                  }
                  fullWidth
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                  disabled={videoDetailsLoading || isSubmitting}
                />

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Data"
                    inputFormat="dd/MM/yyyy"
                    value={values.date}
                    onChange={(newDate) => setFieldValue('date', newDate)}
                    disabled={videoDetailsLoading || isSubmitting}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line
                        {...params}
                        name="date"
                        fullWidth
                        error={Boolean(errors.date)}
                        helperText={errors.date}
                        sx={{ marginBottom: '1rem' }}
                      />
                    )}
                  />
                </LocalizationProvider>

                {thumbnailUrl && (
                  <EventImage src={thumbnailUrl} alt="footage thumbnail" />
                )}

                {isSubmitting || (videoDetailsLoading && <LinearProgress />)}
              </Form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Anuluj</Button>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {selectedFootage ? 'Zapisz' : 'Dodaj'}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
