import { Container, MenuLink } from 'theme/GlobalStyles';
import {
  BottomBarWrapper,
  Content,
  MenuItem,
  SocialMenu,
  Mouse,
} from './styled';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectMobile } from 'redux/App/slice';

const menuItems = [
  {
    id: 1,
    name: 'Instagram',
    link: 'https://www.instagram.com/infamous_skydiving_team/',
  },
  { id: 2, name: 'Facebook', link: 'https://www.facebook.com/InfamousFreefly' },
  { id: 3, name: 'Youtube', link: '#' },
];

function BottomBar() {
  const navigate = useNavigate();

  const mobile = useAppSelector(selectMobile);

  return (
    <BottomBarWrapper>
      <Container>
        <Content>
          <Button onClick={() => navigate('/events')}>Upcoming events</Button>

          <Mouse />

          {!mobile && (
            <SocialMenu>
              {menuItems.map((menuItem) => (
                <MenuItem key={menuItem.id}>
                  <MenuLink
                    href={menuItem.link}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {menuItem.name}
                  </MenuLink>
                </MenuItem>
              ))}
            </SocialMenu>
          )}
        </Content>
      </Container>
    </BottomBarWrapper>
  );
}

export default BottomBar;
