import { useEffect, useState } from 'react';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import { Title, Subtitle, Text, TextButton } from 'theme/GlobalStyles';
import Button from 'components/Button';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import {
  selectIsLoading,
  selectError,
  selectEvents,
  selectTotalDocuments,
  selectNextQuery,
  selectPrevQuery,
  selectSignupPopupOpen,
  selectSuccessAlertOpen,
} from 'redux/events/selectors';
import {
  getEvents,
  getNextPageEvents,
  getPrevPageEvents,
} from 'redux/events/thunk';
import Loader from 'components/Loader';
import { renderDateRange } from 'utils/helperFunctions';
import Pagination from 'components/Pagination';
import { compareAsc } from 'date-fns';
import Alert from 'components/Alert';
import Popup from 'components/Popup';

import {
  PageWrapper,
  Content,
  Header,
  LoaderWrapper,
  Events,
  EventCard,
  ImageWrapper,
  EventImage,
  DarkOverlay,
  EventTitle,
  DetailsRow,
  Label,
  DetailsText,
  SignupsDisabledWrapper,
  SignupsDisabledText,
  ButtonsWrapper,
} from './styled';
import SignupForm from './SignupForm';
import { hideSuccessAlert, setSignupPopupOpen } from 'redux/events/slice';
import Description from 'containers/EventsPage/Description';
import FilteringControls from './FilteringControls';

const rowsPerPage = 6;

export default function EventsPage() {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const error = useAppSelector(selectError);
  const events = useAppSelector(selectEvents);
  const totalEvents = useAppSelector(selectTotalDocuments);
  const nextQuery = useAppSelector(selectNextQuery);
  const prevQuery = useAppSelector(selectPrevQuery);
  const popupOpen = useAppSelector(selectSignupPopupOpen);
  const [descPopupOpen, setDescPopupOpen] = useState(false);
  const successAlertOpen = useAppSelector(selectSuccessAlertOpen);

  const [page, setPage] = useState(0);
  const [selectedEventId, setSelectedEventId] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('all');
  const [selectedYear, setSelectedYear] = useState('2024');

  useEffect(() => {
    dispatch(getEvents({ pageSize: rowsPerPage, selectedMonth, selectedYear }));
  }, [dispatch, selectedMonth, selectedYear]);

  // hide success alert after 5 seconds
  useEffect(() => {
    if (!successAlertOpen) return;

    const timeout = setTimeout(() => {
      dispatch(hideSuccessAlert());
    }, 5000);

    return () => clearTimeout(timeout);
  }, [successAlertOpen]);

  const getEventName = (eventId: string) => {
    const selectedEvent = events.find((event) => event.id === eventId);

    return selectedEvent ? selectedEvent.name : 'Details';
  };

  const renderedContent = () => {
    if (isLoading && !popupOpen) {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }

    if (error) {
      return <Alert type="error">Oops! Something went wrong: {error}</Alert>;
    }

    if (events.length === 0) {
      return <Text style={{ marginTop: '2rem' }}>No events</Text>;
    }

    return (
      <Events>
        {events.map((event) => {
          const pastEvent = compareAsc(event.date.start, new Date()) === -1;

          return (
            <EventCard key={event.id}>
              <ImageWrapper>
                <EventImage src={event.imageUrl} alt={event.name} />
                <DarkOverlay />

                {!pastEvent && event.signupsBlocked && (
                  <SignupsDisabledWrapper>
                    <SignupsDisabledText>Registration soon</SignupsDisabledText>
                  </SignupsDisabledWrapper>
                )}

                {pastEvent && (
                  <SignupsDisabledWrapper>
                    <SignupsDisabledText>Past event</SignupsDisabledText>
                  </SignupsDisabledWrapper>
                )}
              </ImageWrapper>

              <EventTitle>
                <Subtitle>{event.name}</Subtitle>
              </EventTitle>

              <DetailsRow>
                <Label>Date</Label>
                <DetailsText>{renderDateRange(event.date)}</DetailsText>
              </DetailsRow>

              <DetailsRow>
                <Label>Place</Label>
                <DetailsText>{event.location}</DetailsText>
              </DetailsRow>

              <DetailsRow>
                <Label>
                  {event.instructors.length > 1 ? 'Coaches' : 'Coach'}
                </Label>
                <DetailsText $last>{event.instructors.join(', ')}</DetailsText>
              </DetailsRow>

              <ButtonsWrapper $withDetails={Boolean(event?.description)}>
                {event.description && (
                  <TextButton
                    type="button"
                    onClick={() => {
                      setSelectedEventId(event.id!);
                      setDescPopupOpen(true);
                    }}
                  >
                    Details
                  </TextButton>
                )}

                <Button
                  dark
                  long
                  disabled={event.signupsBlocked || pastEvent}
                  onClick={() => {
                    if (event.signupsBlocked || pastEvent) return;

                    setSelectedEventId(event.id!);
                    dispatch(setSignupPopupOpen(true));
                  }}
                >
                  Sign up
                </Button>
              </ButtonsWrapper>
            </EventCard>
          );
        })}
      </Events>
    );
  };

  return (
    <PageWrapper>
      <Navbar dark />

      <Content>
        <Header>
          <Title>Events</Title>

          <FilteringControls
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
          />
        </Header>

        {successAlertOpen && (
          <Alert type="success">
            You have successfully signed up for the event
          </Alert>
        )}

        {renderedContent()}

        {nextQuery && !error && !isLoading && (
          <Pagination
            page={page}
            pageSize={rowsPerPage}
            totalDocuments={totalEvents}
            documentsNumber={events.length}
            onChangePage={(newPage: number) => setPage(newPage)}
            onNextPageClick={() =>
              dispatch(
                getNextPageEvents({
                  nextQuery,
                  page,
                  pageSize: rowsPerPage,
                  totalDocuments: totalEvents,
                  selectedMonth,
                  selectedYear,
                }),
              )
            }
            onPrevPageClick={() => {
              if (!prevQuery) return;

              dispatch(
                getPrevPageEvents({
                  prevQuery,
                  page,
                  pageSize: rowsPerPage,
                  selectedMonth,
                  selectedYear,
                }),
              );
            }}
          />
        )}
      </Content>

      {popupOpen && (
        <Popup
          title="Sign up for the event"
          handleClose={() => dispatch(setSignupPopupOpen(false))}
          small
        >
          <SignupForm eventId={selectedEventId} />
        </Popup>
      )}

      {descPopupOpen && (
        <Popup
          title={getEventName(selectedEventId)}
          handleClose={() => setDescPopupOpen(false)}
          small
        >
          <Description eventId={selectedEventId} />
        </Popup>
      )}

      <Footer />
    </PageWrapper>
  );
}
