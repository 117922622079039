import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

interface AppState {
  mobile: boolean;
}

const initialState: AppState = {
  mobile: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMobile: (state, action: PayloadAction<boolean>) => {
      state.mobile = action.payload;
    },
  },
});

// actions
export const { setMobile } = appSlice.actions;

// selectors
export const selectMobile = (state: RootState) => state.app.mobile;

export default appSlice.reducer;
