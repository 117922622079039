import styled from 'styled-components';

export const SelectContainer = styled.div`
  cursor: pointer;
  position: relative;
  height: 100%;
  z-index: 100;
`;

interface OptionsContainerProps {
  $isOpen: boolean;
}

export const OptionsContainer = styled.ul<OptionsContainerProps>`
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
  position: absolute;
  top: 5rem;
  list-style-type: none;
  width: 100%;
  border: 0.1rem solid ${(props) => props.theme.colors.primary};

  background-color: ${(props) => props.theme.colors.light};
`;

export const Option = styled.li`
  font-family: 'Space Grotesk', sans-serif;
  padding: 1.4rem 1.7rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const SelectedOption = styled.div`
  font-family: 'Space Grotesk', sans-serif;
  border: 0.1rem solid ${(props) => props.theme.colors.primary};
  padding: 1.4rem 1.7rem;
  min-width: 13rem;
  height: 5rem;
  text-align: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }
`;
