import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import {
  selectEvents,
  selectIsLoading,
  selectNextQuery,
  selectPrevQuery,
  selectTotalDocuments,
} from 'redux/events/selectors';
import {
  getEvents,
  getNextPageEvents,
  getPrevPageEvents,
  deleteEvents,
  changeEventSignupsStatus,
} from 'redux/events/thunk';
import { format } from 'date-fns';
import pl from 'date-fns/locale/pl';
import Loader from 'components/Loader';
import AddEventDialog from 'components/Dashboard/Dialogs/AddEvent';
import TableToolbar from './TableToolbar';
import { Event } from 'api/models/Event';

import { Thumbnail } from './styled';
import Button from '@mui/material/Button';

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'image',
    numeric: false,
    disablePadding: false,
    label: 'Zdjęcie',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nazwa',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Termin',
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    label: 'Lokalizacja',
  },
  {
    id: 'instructors',
    numeric: false,
    disablePadding: false,
    label: 'Instruktorzy',
  },
  {
    id: 'participantsNumber',
    numeric: false,
    disablePadding: false,
    label: 'Liczba uczestników',
  },
  {
    id: 'signupsBlocked',
    numeric: false,
    disablePadding: false,
    label: 'Zapisy otwarte',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Akcja',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'Zaznacz wszystkie eventy',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function Events() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const events = useAppSelector(selectEvents);
  const isLoading = useAppSelector(selectIsLoading);
  const totalDocuments = useAppSelector(selectTotalDocuments);
  const nextQuery = useAppSelector(selectNextQuery);
  const prevQuery = useAppSelector(selectPrevQuery);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [newEventDialogOpen, setNewEventDialogOpen] = useState(false);
  const [eventToUpdate, setEventToUpdate] = useState<Event | undefined>(
    undefined,
  );

  useEffect(() => {
    dispatch(getEvents({ pageSize: rowsPerPage }));
  }, [rowsPerPage]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = events.map((e) => e.id!);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleEditClick = () => {
    if (selected.length !== 1) return;

    const selectedEvent = events.find((event) => event.id === selected[0]);
    setEventToUpdate(selectedEvent);
    setNewEventDialogOpen(true);
  };

  const handleChangeSignupStatusClick = (status: boolean) => {
    if (selected.length === 0) return;

    dispatch(
      changeEventSignupsStatus({ eventIds: selected, signupsBlocked: status }),
    );
  };

  const handleDeleteClick = () => {
    dispatch(deleteEvents(selected));
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage > page && nextQuery) {
      dispatch(
        getNextPageEvents({
          nextQuery,
          page,
          pageSize: rowsPerPage,
          totalDocuments,
        }),
      );
    } else {
      if (!prevQuery) return;

      dispatch(
        getPrevPageEvents({
          prevQuery,
          page,
          pageSize: rowsPerPage,
        }),
      );
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalDocuments) : 0;

  if (isLoading && !newEventDialogOpen) return <Loader />;

  return (
    <>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableToolbar
          numSelected={selected.length}
          handleAddNewEventClick={() => setNewEventDialogOpen(true)}
          handleEditEventClick={handleEditClick}
          handleChangeSignupStatusClick={handleChangeSignupStatusClick}
          handleDeleteEventsClick={handleDeleteClick}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="events table">
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={events.length}
            />
            <TableBody>
              {events.map((eventRow) => {
                const isItemSelected = isSelected(eventRow.id!);
                const labelId = `event-${eventRow.id}-checkbox`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, eventRow.id!)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={eventRow.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="checkbox"
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Thumbnail src={eventRow.imageUrl} alt={eventRow.name} />
                    </TableCell>
                    <TableCell>{eventRow.name}</TableCell>
                    <TableCell>
                      {format(eventRow.date.start, 'ccc dd MMM yyyy', {
                        locale: pl,
                      })}{' '}
                      -{' '}
                      {format(eventRow.date.end, 'ccc dd MMM yyyy', {
                        locale: pl,
                      })}
                    </TableCell>
                    <TableCell>{eventRow.location}</TableCell>
                    <TableCell>{eventRow.instructors.join(', ')}</TableCell>
                    <TableCell>{eventRow.participantsNumber || 0}</TableCell>
                    <TableCell>
                      {eventRow.signupsBlocked ? 'NIE' : 'TAK'}
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => navigate(`${eventRow.id}`)}>
                        Lista uczestników
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 139 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={totalDocuments}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <AddEventDialog
        isOpen={newEventDialogOpen}
        handleClose={() => {
          setNewEventDialogOpen(false);
          setEventToUpdate(undefined);
        }}
        selectedEvent={eventToUpdate}
        withDescription={Boolean(
          eventToUpdate?.description && eventToUpdate.description !== '',
        )}
      />
    </>
  );
}
