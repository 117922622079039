import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

interface LoaderProps {
  linear?: boolean;
}

export default function Loader({ linear }: LoaderProps) {
  return (
    <Box
      sx={{
        width: '100%',
        display: linear ? 'block' : 'flex',
        justifyContent: 'center',
      }}
    >
      {linear ? <LinearProgress /> : <CircularProgress />}
    </Box>
  );
}
