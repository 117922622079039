import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '../theme';
import AppBar from 'components/Dashboard/Appbar';
import Drawer from 'components/Dashboard/Drawer';
import Toolbar from '@mui/material/Toolbar';

import { Container, Content } from './styled';

interface LayoutProps {
  children: JSX.Element;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <AppBar />
      <Drawer />

      <Container>
        <Content>
          <Toolbar />
          {children}
        </Content>
      </Container>
    </ThemeProvider>
  );
}
