import { Participant } from 'api/models/Participant';
import { db } from 'api/firebase';
import {
  collection,
  getDocs,
  query,
  orderBy,
  startAfter,
  limit,
  getCountFromServer,
  endBefore,
  limitToLast,
  where,
  documentId,
  Query,
  DocumentData,
} from 'firebase/firestore';
import { SortByOrder } from 'redux/participants/slice';
import _ from 'lodash';

const participantsCollection = collection(db, 'participants');

export const fetchParticipants = async (
  pageSize: number,
  sortBy: string,
  sortByOrder: SortByOrder,
) => {
  try {
    const countSnapshot = await getCountFromServer(participantsCollection);
    const totalDocuments = countSnapshot.data().count;

    const q = query(
      participantsCollection,
      orderBy(sortBy, sortByOrder),
      limit(pageSize),
    );

    const snapshot = await getDocs(q);

    const participants = snapshot.docs.map((participant) => ({
      ...participant.data(),
      id: participant.id,
    })) as Participant[];

    // prepare next query (pagination)
    let nextQuery: Query<DocumentData> | undefined;
    if (participants.length < totalDocuments) {
      const lastParticipant = snapshot.docs[snapshot.docs.length - 1];

      nextQuery = query(
        participantsCollection,
        orderBy(sortBy, sortByOrder),
        startAfter(lastParticipant),
        limit(pageSize),
      );
    }

    return { participants, totalDocuments, nextQuery };
  } catch (error) {
    console.error(`Error while fetching participants: ${error}`);
  }
};

export const fetchNextPageParticipants = async (
  next: Query<DocumentData>,
  page: number,
  pageSize: number,
  sortBy: string,
  sortByOrder: SortByOrder,
  totalDocuments: number,
) => {
  try {
    const snapshot = await getDocs(next);

    const participants = snapshot.docs.map((participant) => ({
      ...participant.data(),
      id: participant.id,
    })) as Participant[];

    // prepare next and prev query (pagination)
    let nextQuery: Query<DocumentData> | undefined;
    if (pageSize * page + participants.length < totalDocuments) {
      const lastParticipant = snapshot.docs[snapshot.docs.length - 1];

      nextQuery = query(
        participantsCollection,
        orderBy(sortBy, sortByOrder),
        startAfter(lastParticipant),
        limit(pageSize),
      );
    }

    const firstParticipant = snapshot.docs[0];
    const prevQuery = query(
      participantsCollection,
      orderBy(sortBy, sortByOrder),
      endBefore(firstParticipant),
      limitToLast(pageSize),
    );

    return { participants, nextQuery, prevQuery };
  } catch (error) {
    console.error(`Error while fetching participants: ${error}`);
  }
};

export const fetchPrevPageParticipants = async (
  prev: Query<DocumentData>,
  page: number,
  pageSize: number,
  sortBy: string,
  sortByOrder: SortByOrder,
) => {
  try {
    const snapshot = await getDocs(prev);

    const participants = snapshot.docs.map((participant) => ({
      ...participant.data(),
      id: participant.id,
    })) as Participant[];

    // prepare next and prev query (pagination)
    let prevQuery: Query<DocumentData> | undefined;
    if (page > 0) {
      const firstParticipant = snapshot.docs[0];

      prevQuery = query(
        participantsCollection,
        orderBy(sortBy, sortByOrder),
        endBefore(firstParticipant),
        limitToLast(pageSize),
      );
    }

    const lastParticipant = snapshot.docs[snapshot.docs.length - 1];

    const nextQuery = query(
      participantsCollection,
      orderBy(sortBy, sortByOrder),
      startAfter(lastParticipant),
      limit(pageSize),
    );

    return { participants, nextQuery, prevQuery };
  } catch (error) {
    console.error(`Error while fetching participants: ${error}`);
  }
};

export const fetchParticipantsByIds = async (
  participantsJunction: { id: string; signUpDate: Date }[],
) => {
  const ids = participantsJunction.map((participant) => participant.id);

  try {
    const result = await Promise.all(
      _.chunk(ids, 10).map(async (chunkIds) => {
        const participants = await getDocs(
          query(participantsCollection, where(documentId(), 'in', chunkIds)),
        );

        return participants.docs
          .filter((doc) => doc.exists())
          .map((doc) => {
            return { ...doc.data(), id: doc.id } as Participant;
          });
      }),
    );

    const participants = result.flat(1);
    const participantsWithDates = participants.map((participant) => {
      const signUpDate = participantsJunction.find(
        (p) => p.id === participant.id,
      )?.signUpDate;
      return { ...participant, signUpDate };
    });

    return participantsWithDates;
  } catch (error) {
    console.error(`Error while fetching participants by ids: ${error}`);
  }
};
