import RichTextEditor from 'components/Dashboard/RichTextEditor';
import { useEffect, useState } from 'react';
import { selectEvents } from 'redux/events/selectors';
import { useAppSelector } from 'redux/hooks';

interface Props {
  eventId: string;
}

export default function Description({ eventId }: Props) {
  const events = useAppSelector(selectEvents);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (!eventId) return;

    const selectedEvent = events.find((event) => event.id === eventId);

    if (selectedEvent && selectedEvent.description) {
      setDescription(selectedEvent.description);
    }
  }, [eventId]);

  if (description) {
    return <RichTextEditor description={description} readOnly />;
  }

  return null;
}
