import { useState, useEffect } from 'react';
import { StyledNavLink } from 'theme/GlobalStyles';
import { NavLink } from 'react-router-dom';
import {
  Toolbar,
  Content,
  Logo,
  NavItems,
  NavItem,
  MobileMenuIcon,
  MobileMenuWrapper,
  MobileNavItems,
  SocialLinks,
  SocialLink,
  CloseIconWrapper,
  MobileContent,
} from './styled';
import LogoImage from 'assets/images/logo.svg';
import HamburgerIcon from 'assets/hamburger.svg';
import CloseIcon from 'assets/close.svg';
import { useAppSelector } from 'redux/hooks';
import { selectMobile } from 'redux/App/slice';

const navItems = [
  { id: 1, name: 'About us', link: '/' },
  { id: 2, name: 'Footage', link: '/footage' },
  { id: 3, name: 'Events', link: '/events' },
];

const socialItems = [
  {
    id: 1,
    name: 'Instagram',
    link: 'https://www.instagram.com/infamous_skydiving_team/',
  },
  { id: 2, name: 'Facebook', link: 'https://www.facebook.com/InfamousFreefly' },
  { id: 3, name: 'Youtube', link: '#' },
];

interface NavbarProps {
  dark?: boolean;
}

function Navbar({ dark }: NavbarProps) {
  const [stickyToolbar, setStickyToolbar] = useState(dark || false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const mobile = useAppSelector(selectMobile);

  // Prevent page from scrolling when mobile menu is visible
  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [mobileMenuOpen]);

  // Change navbar styles when user scrolls
  useEffect(() => {
    if (dark) return;

    const handleScroll = () => {
      setStickyToolbar(window.scrollY > 60);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Toolbar $sticky={stickyToolbar}>
      <Content>
        <NavLink to="/">
          <Logo src={LogoImage} alt="Infamous skydiving team logo" />
        </NavLink>

        {mobile ? (
          <MobileMenuIcon
            src={HamburgerIcon}
            alt="Mobile menu"
            onClick={() => setMobileMenuOpen(true)}
          />
        ) : (
          <nav>
            <NavItems>
              {navItems.map((navItem) => (
                <NavItem key={navItem.id}>
                  <StyledNavLink to={navItem.link}>
                    {navItem.name}
                  </StyledNavLink>
                </NavItem>
              ))}
            </NavItems>
          </nav>
        )}
      </Content>

      {mobile && mobileMenuOpen && (
        <MobileMenuWrapper>
          <CloseIconWrapper $sticky={stickyToolbar}>
            <MobileContent>
              <img
                src={CloseIcon}
                alt="Close"
                onClick={() => setMobileMenuOpen(false)}
              />
            </MobileContent>
          </CloseIconWrapper>

          <MobileNavItems $sticky={stickyToolbar}>
            {navItems.map((navItem) => (
              <NavItem key={navItem.id}>
                <StyledNavLink to={navItem.link}>{navItem.name}</StyledNavLink>
              </NavItem>
            ))}
          </MobileNavItems>

          <SocialLinks>
            {socialItems.map((socialItem) => (
              <SocialLink
                key={socialItem.id}
                href={socialItem.link}
                target="_blank"
                rel="noreferrer noopener"
              >
                {socialItem.name}
              </SocialLink>
            ))}
          </SocialLinks>
        </MobileMenuWrapper>
      )}
    </Toolbar>
  );
}

export default Navbar;
