import styled, { css } from 'styled-components';
import { Editable } from 'slate-react';

export const EditorWrapper = styled.div`
  position: relative;
`;

interface StyledEditorProps {
  $readOnly: boolean;
}

export const StyledEditor = styled(Editable)<StyledEditorProps>`
  margin-top: 0.5rem;
  padding: 6rem 1.4rem 1.6rem 1.4rem;
  border: 1px solid ${(props) => props.theme.colors.border.gray};
  border-radius: 0.4rem;
  outline: none;
  z-index: 1;

  &:hover {
    border-color: ${(props) => props.theme.colors.primary};
  }

  ${(props) =>
    props.$readOnly &&
    css`
      padding: 1.6rem 0;
      border: none;
      border-radius: none;
    `}
`;

export const Toolbar = styled.div`
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  right: 0.1rem;
  width: calc(100% - 0.2rem);
  border-bottom: 0.1rem solid ${(props) => props.theme.colors.border.gray};
  z-index: 10;
  display: flex;
  align-items: center;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  border-right: 0.1rem solid ${(props) => props.theme.colors.border.gray};
`;

interface ToolbarButtonProps {
  $isActive: boolean;
  $first?: boolean;
}

export const ToolbarButton = styled.button<ToolbarButtonProps>`
  background-color: ${(props) =>
    props.$isActive ? props.theme.colors.gray.veryLight : 'transparent'};
  border: none;
  margin: 0;
  padding: 1rem;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  border-top-left-radius: ${(props) => (props.$first ? '0.4rem' : 0)};

  &:hover {
    background-color: ${(props) => props.theme.colors.gray.veryLight};
  }
`;

export const Ol = styled.ol`
  margin-left: 2rem;
`;

export const Ul = styled.ul`
  margin-left: 2rem;
`;
