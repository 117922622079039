import { auth } from 'api/firebase';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { User } from 'api/models/User';

export const login = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      username,
      password,
    );

    const user: User = {
      id: userCredential.user.uid,
      username: userCredential.user.email || '',
    };

    return user;
  } catch (error: any) {
    console.error(`Error while signing in: ${error}`);
    throw error.message;
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error(`Error while signing out: ${error}`);
  }
};
