import { styled } from '@mui/material/styles';

export const Container = styled('div')(() => ({
  width: 'calc(100% - 240px)',
  marginLeft: '240px',
}));

export const Content = styled('div')(() => ({
  maxWidth: '1500px',
  margin: 'auto',
  padding: '4rem 2rem',
}));
