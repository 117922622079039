interface Props {
  attributes: any;
  children: React.ReactNode;
  leaf: any;
}

export const Leaf = ({ attributes, children, leaf }: Props) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};
