import styled from 'styled-components';
import { Container, Text } from 'theme/GlobalStyles';

export const AboutUsContainer = styled(Container)`
  margin-top: 6rem;
`;

export const Header = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: justify;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Description = styled.div`
  width: 70%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Logo = styled.img`
  width: 12.2rem;
  height: auto;

  @media (max-width: 900px) {
    margin-bottom: 4rem;
  }
`;

export const StyledText = styled(Text)`
  text-transform: uppercase;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
`;

export const TextDivider = styled.div`
  height: 2rem;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const Cards = styled.section`
  margin-top: 6rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10rem;

  @media (max-width: 1300px) {
    gap: 5rem;
  }

  @media (max-width: 900px) {
    display: block;
  }
`;

export const Card = styled.article`
  @media (max-width: 900px) {
    &:not(first-of-type) {
      margin-top: 6rem;
    }
  }
`;

export const ImageWrapper = styled.div`
  width: auto;
  min-height: 50rem;
  position: relative;
  border: 0.3rem solid ${(props) => props.theme.colors.primary};

  @media (max-width: 1300px) {
    min-height: 40rem;
  }
`;

export const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1a1a;
  opacity: 0.2;
`;

export const ProfileImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: top;
  object-fit: cover;
`;

export const CardTitle = styled.div`
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  border-bottom: 0.2rem solid ${(props) => props.theme.colors.primary};
`;

export const CardSubtitle = styled(Text)`
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 4rem;
`;

export const BulletPoint = styled.li`
  border-bottom: 0.05rem solid ${(props) => props.theme.colors.primary};
  margin: 2rem 0;
  padding-bottom: 0.5rem;
  list-style: none;

  &:last-of-type {
    border-bottom: none;
  }
`;

export const AdditionalNote = styled(Text)`
  margin-top: 3rem;
`;
