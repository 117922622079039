import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Event } from 'api/models/Event';
import { DocumentData, Query } from 'firebase/firestore';
import {
  getEvents,
  addEvent,
  updateEvent,
  deleteEvents,
  signupToEvent,
  signOffEvent,
  changeEventSignupsStatus,
  getNextPageEvents,
  getPrevPageEvents,
  fetchEventById,
} from 'redux/events/thunk';

interface EventsState {
  isLoading: boolean;
  error: string | undefined;
  events: Event[];
  eventToUpdate: Event | undefined;
  totalDocuments: number;
  selectedEvent: Event | undefined;
  nextQuery: Query<DocumentData> | undefined;
  prevQuery: Query<DocumentData> | undefined;
  signupPopupOpen: boolean;
  successAlertOpen: boolean;
  refetchParticipants: boolean;
}

const initialState: EventsState = {
  isLoading: false,
  error: undefined,
  events: [],
  eventToUpdate: undefined,
  totalDocuments: 0,
  selectedEvent: undefined,
  nextQuery: undefined,
  prevQuery: undefined,
  signupPopupOpen: false,
  successAlertOpen: false,
  refetchParticipants: false,
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEventToUpdate: (state, action: PayloadAction<Event | undefined>) => {
      state.eventToUpdate = action.payload;
    },
    setSignupPopupOpen: (state, action: PayloadAction<boolean>) => {
      state.signupPopupOpen = action.payload;
    },
    hideSuccessAlert: (state) => {
      state.successAlertOpen = false;
    },
    setRefetchParticipants: (state) => {
      state.refetchParticipants = !state.refetchParticipants;
    },
  },
  extraReducers: (builder) => {
    // GET EVENTS
    builder.addCase(getEvents.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      getEvents.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<
          | {
              events: Event[];
              totalDocuments: number;
              nextQuery: Query<DocumentData> | undefined;
            }
          | undefined
        >,
      ) => {
        state.isLoading = false;
        state.events = payload ? payload.events : [];
        state.totalDocuments = payload ? payload.totalDocuments : 0;
        state.nextQuery = payload?.nextQuery;
      },
    );
    builder.addCase(getEvents.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // GET NEXT PAGE EVENTS
    builder.addCase(getNextPageEvents.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      getNextPageEvents.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<
          | {
              events: Event[];
              nextQuery: Query<DocumentData> | undefined;
              prevQuery: Query<DocumentData>;
            }
          | undefined
        >,
      ) => {
        state.isLoading = false;
        state.events = payload?.events || [];
        state.nextQuery = payload?.nextQuery;
        state.prevQuery = payload?.prevQuery;
      },
    );
    builder.addCase(getNextPageEvents.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // GET PREV PAGE EVENTS
    builder.addCase(getPrevPageEvents.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      getPrevPageEvents.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<
          | {
              events: Event[] | undefined;
              nextQuery: Query<DocumentData>;
              prevQuery: Query<DocumentData> | undefined;
            }
          | undefined
        >,
      ) => {
        state.isLoading = false;
        state.events = payload?.events || [];
        state.nextQuery = payload?.nextQuery;
        state.prevQuery = payload?.prevQuery;
      },
    );
    builder.addCase(getPrevPageEvents.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // ADD EVENT
    builder.addCase(addEvent.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      addEvent.fulfilled,
      (state, { payload }: PayloadAction<Event | undefined>) => {
        state.isLoading = false;
        if (payload) state.events.push(payload);
      },
    );
    builder.addCase(addEvent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // UPDATE EVENT
    builder.addCase(updateEvent.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      updateEvent.fulfilled,
      (state, { payload }: PayloadAction<Event | undefined>) => {
        state.isLoading = false;
        if (payload)
          state.events = state.events.map((event) =>
            event.id === payload.id ? payload : event,
          );
      },
    );
    builder.addCase(updateEvent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // CHANGE EVENT SIGNUP STATUS
    builder.addCase(changeEventSignupsStatus.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      changeEventSignupsStatus.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<
          { eventIds: string[]; signupsBlocked: boolean } | undefined
        >,
      ) => {
        state.isLoading = false;
        if (payload)
          state.events = state.events.map((event) =>
            payload.eventIds.includes(event.id!)
              ? { ...event, signupsBlocked: payload.signupsBlocked }
              : event,
          );
      },
    );
    builder.addCase(changeEventSignupsStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // DELETE EVENT
    builder.addCase(deleteEvents.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      deleteEvents.fulfilled,
      (state, { payload }: PayloadAction<string[] | undefined>) => {
        state.isLoading = false;
        if (payload)
          state.events = state.events.filter(
            (event) => !payload.includes(event.id!),
          );
      },
    );
    builder.addCase(deleteEvents.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // SIGN UP TO EVENT
    builder.addCase(signupToEvent.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      signupToEvent.fulfilled,
      (state, { payload }: PayloadAction<Event | undefined>) => {
        state.isLoading = false;
        if (payload)
          state.events = state.events.map((event) =>
            event.id === payload.id ? payload : event,
          );
        state.signupPopupOpen = false;
        state.successAlertOpen = true;
      },
    );
    builder.addCase(signupToEvent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // SIGN OFF EVENT
    builder.addCase(signOffEvent.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      signOffEvent.fulfilled,
      (state, { payload }: PayloadAction<Event | undefined>) => {
        state.isLoading = false;
        if (payload)
          state.events = state.events.map((event) =>
            event.id === payload.id ? payload : event,
          );
        state.refetchParticipants = !state.refetchParticipants;
      },
    );
    builder.addCase(signOffEvent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });

    // FETCH EVENT BY ID
    builder.addCase(fetchEventById.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchEventById.fulfilled,
      (state, { payload }: PayloadAction<Event | undefined>) => {
        state.isLoading = false;
        state.selectedEvent = payload;
      },
    );
    builder.addCase(fetchEventById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ? action.payload : action.error.message;
    });
  },
});

export const { setEventToUpdate, setSignupPopupOpen, hideSuccessAlert } =
  eventsSlice.actions;

export default eventsSlice.reducer;
