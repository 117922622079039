import styled, { css } from 'styled-components';
import { Container } from 'theme/GlobalStyles';

interface ToolbarProps {
  $sticky: boolean;
}

export const Toolbar = styled.div<ToolbarProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 15rem;
  z-index: 1000;
  transition: all 0.2s ease-in-out;
  background-color: transparent;

  ${(props) =>
    props.$sticky &&
    css`
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      height: 8rem;
      background-color: ${props.theme.colors.primary};
    `}
`;

export const Content = styled(Container)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.img`
  width: 6rem;
  height: auto;
`;

export const NavItems = styled.ul`
  display: flex;
  align-items: center;
  gap: 3rem;
`;

export const NavItem = styled.li`
  list-style: none;
`;

// MOBILE MENU
export const MobileMenuIcon = styled.img`
  cursor: pointer;
`;

export const CloseIconWrapper = styled.div<ToolbarProps>`
  width: 100%;
  height: ${(props) => (props.$sticky ? '8rem' : '15rem')};
`;

export const MobileContent = styled(Container)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > img {
    cursor: pointer;
  }
`;

export const MobileMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: calc(100vh - env(safe-area-inset-bottom));
  padding-bottom: calc(3rem + env(safe-area-inset-bottom));
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const MobileNavItems = styled.ul<ToolbarProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  margin-top: ${(props) => (props.$sticky ? '-8rem' : '-15rem')};
`;

export const SocialLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const SocialLink = styled.a`
  text-transform: uppercase;
`;
