import { RootState } from 'redux/store';

export const selectIsLoading = (state: RootState) => state.events.isLoading;
export const selectError = (state: RootState) => state.events.error;
export const selectEvents = (state: RootState) => state.events.events;
export const selectEventToUpdate = (state: RootState) =>
  state.events.eventToUpdate;
export const selectTotalDocuments = (state: RootState) =>
  state.events.totalDocuments;
export const selectSelectedEvent = (state: RootState) =>
  state.events.selectedEvent;
export const selectNextQuery = (state: RootState) => state.events.nextQuery;
export const selectPrevQuery = (state: RootState) => state.events.prevQuery;
export const selectSignupPopupOpen = (state: RootState) =>
  state.events.signupPopupOpen;
export const selectSuccessAlertOpen = (state: RootState) =>
  state.events.successAlertOpen;
export const selectRefetchParticipants = (state: RootState) =>
  state.events.refetchParticipants;
