import Select from 'components/Select';
import { FilteringContainer, FiltersLabel } from './styled';
import { months } from './constants';

interface Props {
  selectedMonth: string;
  selectedYear: string;
  setSelectedMonth: (value: string) => void;
  setSelectedYear: (value: string) => void;
}

export default function FilteringControls({
  selectedMonth,
  selectedYear,
  setSelectedMonth,
  setSelectedYear,
}: Props) {
  const currentYear = new Date().getFullYear();
  const futureYear = currentYear + 1;

  const years = [
    { value: currentYear.toString(), label: currentYear.toString() },
    { value: futureYear.toString(), label: futureYear.toString() },
  ];

  return (
    <FilteringContainer>
      <FiltersLabel>Filters</FiltersLabel>

      <Select
        options={months}
        value={selectedMonth}
        onChange={setSelectedMonth}
      />

      <Select options={years} value={selectedYear} onChange={setSelectedYear} />
    </FilteringContainer>
  );
}
