import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import {
  selectFootage,
  selectIsLoading,
  selectNextQuery,
  selectPrevQuery,
  selectTotalDocuments,
} from 'redux/footage/selectors';
import {
  deleteFootage,
  fetchFootage,
  fetchNextPageFootage,
  fetchPrevPageFootage,
} from 'redux/footage/thunk';
import { format } from 'date-fns';
import pl from 'date-fns/locale/pl';
import Loader from 'components/Loader';
import TableToolbar from './TableToolbar';
import { Footage as IFootage } from 'api/models/Footage';
import AddFootageDialog from 'components/Dashboard/Dialogs/AddFootage';

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nazwa',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Data',
  },
  {
    id: 'link',
    numeric: false,
    disablePadding: false,
    label: 'Link',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'Zaznacz wszystkie',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function Footage() {
  const dispatch = useAppDispatch();

  const footage = useAppSelector(selectFootage);
  const isLoading = useAppSelector(selectIsLoading);
  const totalDocuments = useAppSelector(selectTotalDocuments);
  const nextQuery = useAppSelector(selectNextQuery);
  const prevQuery = useAppSelector(selectPrevQuery);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [newFootageDialogOpen, setNewFootageDialogOpen] = useState(false);
  const [footageToUpdate, setFootageToUpdate] = useState<IFootage | undefined>(
    undefined,
  );

  useEffect(() => {
    dispatch(fetchFootage(rowsPerPage));
  }, [rowsPerPage]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = footage.map((f) => f.id!);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleEditClick = () => {
    if (selected.length !== 1) return;

    const selectedFootage = footage.find((f) => f.id === selected[0]);
    setFootageToUpdate(selectedFootage);
    setNewFootageDialogOpen(true);
  };

  const handleDeleteClick = () => {
    dispatch(deleteFootage(selected));
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage > page && nextQuery) {
      dispatch(
        fetchNextPageFootage({
          nextQuery,
          page,
          pageSize: rowsPerPage,
          totalDocuments,
        }),
      );
    } else {
      if (!prevQuery) return;

      dispatch(
        fetchPrevPageFootage({
          prevQuery,
          page,
          pageSize: rowsPerPage,
        }),
      );
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalDocuments) : 0;

  if (isLoading && !newFootageDialogOpen) return <Loader />;

  return (
    <>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableToolbar
          numSelected={selected.length}
          handleAddNewFootageEventClick={() => setNewFootageDialogOpen(true)}
          handleEditFootageClick={handleEditClick}
          handleDeleteFootageClick={handleDeleteClick}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="footage table">
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={footage.length}
            />
            <TableBody>
              {footage.map((footageRow) => {
                const isItemSelected = isSelected(footageRow.id!);
                const labelId = `footage-${footageRow.id}-checkbox`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, footageRow.id!)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={footageRow.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="checkbox"
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell>{footageRow.name}</TableCell>
                    <TableCell>
                      {format(footageRow.date, 'ccc dd MMM yyyy', {
                        locale: pl,
                      })}
                    </TableCell>
                    <TableCell>{footageRow.youtubeUrl}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={totalDocuments}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <AddFootageDialog
        isOpen={newFootageDialogOpen}
        handleClose={() => {
          setNewFootageDialogOpen(false);
          setFootageToUpdate(undefined);
        }}
        selectedFootage={footageToUpdate}
      />
    </>
  );
}
