import { createAsyncThunk } from '@reduxjs/toolkit';
import { Footage } from 'api/models/Footage';
import {
  fetchFootage as dbFetchFootage,
  addFootage as dbAddFootage,
  updateFootage as dbUpdateFootage,
  deleteFootage as dbDeleteFootage,
  fetchNextPageFootage as dbFetchNextPageFootage,
  fetchPrevPageFootage as dbFetchPrevPageFootage,
} from 'api/controllers/footage';
import { DocumentData, Query } from 'firebase/firestore';

export const fetchFootage = createAsyncThunk<
  | {
      footage: Footage[];
      totalDocuments: number;
      nextQuery: Query<DocumentData> | undefined;
    }
  | undefined,
  number,
  { rejectValue: string }
>('footage/fetchFootage', async (pageSize, { rejectWithValue }) => {
  try {
    const results = await dbFetchFootage(pageSize);
    return results;
  } catch (error: any) {
    if (!error.response.message) throw error;

    rejectWithValue(error.response.message);
  }
});

export const fetchNextPageFootage = createAsyncThunk<
  | {
      footage: Footage[];
      nextQuery: Query<DocumentData> | undefined;
      prevQuery: Query<DocumentData>;
    }
  | undefined,
  {
    nextQuery: Query<DocumentData>;
    page: number;
    pageSize: number;
    totalDocuments: number;
  },
  { rejectValue: string }
>(
  'footage/fetchNextPageFootage',
  async (
    { nextQuery, page, pageSize, totalDocuments },
    { rejectWithValue, getState },
  ) => {
    try {
      const results = await dbFetchNextPageFootage(
        nextQuery,
        page,
        pageSize,
        totalDocuments,
      );

      return results;
    } catch (error: any) {
      if (!error.response.message) throw error;

      rejectWithValue(error.response.message);
    }
  },
);

export const fetchPrevPageFootage = createAsyncThunk<
  | {
      footage: Footage[] | undefined;
      nextQuery: Query<DocumentData>;
      prevQuery: Query<DocumentData> | undefined;
    }
  | undefined,
  {
    prevQuery: Query<DocumentData>;
    page: number;
    pageSize: number;
  },
  { rejectValue: string }
>(
  'footage/fetchPrevPageFootage',
  async ({ prevQuery, page, pageSize }, { rejectWithValue, getState }) => {
    try {
      const results = await dbFetchPrevPageFootage(prevQuery, page, pageSize);
      return results;
    } catch (error: any) {
      if (!error.response.message) throw error;

      rejectWithValue(error.response.message);
    }
  },
);

export const addFootage = createAsyncThunk<
  Footage | undefined,
  Footage,
  { rejectValue: string }
>('footage/addFootage', async (footage, { rejectWithValue }) => {
  try {
    const addedFootage = await dbAddFootage(footage);

    if (!addedFootage) throw new Error('Unexpected error');

    return addedFootage;
  } catch (error: any) {
    if (!error.response.message) throw error;

    rejectWithValue(error.response.message);
  }
});

export const updateFootage = createAsyncThunk<
  Footage | undefined,
  { footageId: string; newFootage: Footage },
  { rejectValue: string }
>(
  'footage/updateFootage',
  async ({ footageId, newFootage }, { rejectWithValue }) => {
    try {
      const updatedFootage = await dbUpdateFootage({ footageId, newFootage });

      if (!updatedFootage) throw new Error('Unexpected error');

      return updatedFootage;
    } catch (error: any) {
      if (!error.response.message) throw error;

      rejectWithValue(error.response.message);
    }
  },
);

export const deleteFootage = createAsyncThunk<
  string[] | undefined,
  string[],
  { rejectValue: string }
>('footage/deleteFootage', async (footageIds, { rejectWithValue }) => {
  try {
    const deletedFootage = await dbDeleteFootage(footageIds);

    if (!deletedFootage) throw new Error('Unexpected error');

    return deletedFootage;
  } catch (error: any) {
    if (!error.response.message) throw error;

    rejectWithValue(error.response.message);
  }
});
