import React, { useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import {
  selectIsLoading,
  selectRefetchParticipants,
  selectSelectedEvent,
} from 'redux/events/selectors';
import { selectIsLoading as selectIsParticipantsLoading } from 'redux/participants/selectors';
import { fetchEventById } from 'redux/events/thunk';
import Loader from 'components/Loader';
import { fetchEventParticipants } from 'redux/participants/thunk';
import ParticipantsTable from './ParticipantsTable';

export default function EventInfo() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoading = useAppSelector(selectIsLoading);
  const selectedEvent = useAppSelector(selectSelectedEvent);
  const isParticipantsLoading = useAppSelector(selectIsParticipantsLoading);
  const refetchParticipants = useAppSelector(selectRefetchParticipants);

  const { id } = useParams();

  useEffect(() => {
    if (!id) return;

    dispatch(fetchEventById(id));
    dispatch(fetchEventParticipants(id));
  }, [id, refetchParticipants, dispatch]);

  const handleBackToEvents = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    navigate('/dashboard/events');
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleBackToEvents}
    >
      Eventy
    </Link>,
    <Typography key="2" color="text.primary">
      {selectedEvent?.name}
    </Typography>,
  ];

  if (isLoading || isParticipantsLoading) return <Loader />;

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ marginBottom: '2rem' }}
      >
        {breadcrumbs}
      </Breadcrumbs>

      <ParticipantsTable />
    </>
  );
}
