import { createAsyncThunk } from '@reduxjs/toolkit';
import { User } from 'api/models/User';
import { login as dbLogin } from 'api/controllers/auth';

export const login = createAsyncThunk<
  User | undefined,
  { username: string; password: string },
  { rejectValue: string }
>('auth/login', async (params, { rejectWithValue }) => {
  try {
    const newUser = await dbLogin(params);

    return newUser;
  } catch (error: any) {
    if (typeof error === 'string') {
      const message =
        error.includes('auth/invalid-email') ||
        error.includes('auth/wrong-password')
          ? 'Invalid username or password'
          : error;
      return rejectWithValue(message);
    }

    throw error;
  }
});
