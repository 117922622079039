import { useState, useRef } from 'react';
import {
  SelectContainer,
  OptionsContainer,
  Option,
  SelectedOption,
} from './styled';
import { useOutsideClick } from 'hooks/useOutsideClick';

interface Props {
  options: {
    value: string;
    label: string;
  }[];
  value: string;
  onChange: (value: string) => void;
}

export default function Select({ options, value, onChange }: Props) {
  const containerRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (newValue: string) => {
    onChange(newValue);
    setIsOpen(false);
  };

  useOutsideClick(containerRef, () => setIsOpen(false));

  return (
    <SelectContainer ref={containerRef}>
      <SelectedOption onClick={() => setIsOpen(true)}>
        <p>{options.find((option) => option.value === value)?.label}</p>
      </SelectedOption>

      <OptionsContainer $isOpen={isOpen}>
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            onClick={() => handleOptionClick(option.value)}
          >
            {option.label}
          </Option>
        ))}
      </OptionsContainer>
    </SelectContainer>
  );
}
