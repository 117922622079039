import { useEffect } from 'react';
import { setUser } from 'redux/auth/slice';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { GlobalStyles } from 'theme/GlobalStyles';
import { auth } from 'api/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { User } from 'api/models/User';

import ErrorPage from 'containers/ErrorPage';
import LoginPage from 'containers/LoginPage';
import LandingPage from 'containers/LandingPage';
import Dashboard from 'containers/Dashboard';
import Footage from 'containers/Dashboard/Footage';
import Events from 'containers/Dashboard/Events';
import Participants from 'containers/Dashboard/Participants';
import EventInfo from 'containers/Dashboard/EventInfo';
import EventsPage from 'containers/EventsPage';
import FootagePage from 'containers/FootagePage';

import ProtectedRoute from 'utils/ProtectedRoute';
import { selectUser } from 'redux/auth/selectors';
import { setMobile } from 'redux/App/slice';

function App() {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);

  // listen to screen width changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 900px)');

    // initial check
    dispatch(setMobile(mediaQuery.matches));

    const updateMobile = (e: MediaQueryListEvent) => {
      dispatch(setMobile(e.matches));
    };
    mediaQuery.addEventListener('change', updateMobile);

    return () => mediaQuery.removeEventListener('change', updateMobile);
  }, []);

  // set auth state change listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userDoc) => {
      if (userDoc) {
        const loggedInUser: User = {
          id: userDoc.uid,
          username: userDoc.email || '',
        };

        dispatch(setUser(loggedInUser));
      } else {
        dispatch(setUser(undefined));
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <GlobalStyles />

      <Router>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path="events" element={<EventsPage />} />
          <Route path="footage" element={<FootagePage />} />
          <Route path="admin-login" element={<LoginPage />} />
          <Route
            path="dashboard"
            element={
              <ProtectedRoute user={user}>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            <Route path="footage" element={<Footage />} />
            <Route path="events" element={<Events />} />
            <Route path="events/:id" element={<EventInfo />} />
            <Route path="participants" element={<Participants />} />
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
